import React, { useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { CroppyProvider } from "croppy";
import rootSaga from "fitbud/sagas";
import { CircularProgress } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import FirebaseAuthProvider, {
  FirebaseAuthContext
} from "fitbud/providers/firebase-auth";
import { TagsProvider } from "fitbud/providers/tagsProvider";
import ContractProvider from "fitbud/providers/contract";
import AclProvider from "fitbud/providers/acl-provider";
import { store, sagaMiddleware } from "fitbud/redux/store";
import RoleProvider from "fitbud/providers/roleProvider";
import Welcome from "fitbud/views/welcome";
import Login from "fitbud/views/login";
import Signup from "fitbud/views/signup";
import Signin from "fitbud/views/signin";
import Router from "fitbud/router";
import Intercomm from "fitbud/utils/intercomm";
import * as Sentry from "@sentry/browser";
import Notifier from "fitbud/components/notifier";
import StyledLoaderError from 'fitbud/components/loaders-errors';
import AIProvider from "fitbud/providers/ai-provider";

const Loader = props => {
  return (
    <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
      <CircularProgress />
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }

  render() {
   if(this.state.hasError) return (
      <StyledLoaderError
        error
        className="min-vh-100"
        retry={() => {
          window.location.reload();
        }}
      />
    );
    return this.props.children;
  }
}

const Root = (props) => {
  const { authLoading, authUser, cid } = useContext(FirebaseAuthContext);

  useEffect(()=>{
    if(!cid) return ;
    sagaMiddleware.run(rootSaga,cid);
  },[cid])

  if (!!authLoading) return <Loader />;
  if (window.location.pathname === "/sudo" && !authUser) return <Login />;
  if (window.location.pathname === "/sign-up" && !authUser) return <Signup />;
  if (window.location.pathname === "/reset-password" || !authUser) return <Signin />;

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: authUser.uid,
      email: authUser.email
    });
    scope.setTag("cid", cid);
  });

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <RoleProvider>
          <Notifier />
          <Intercomm />
          <ContractProvider>
            <AclProvider>
              <AIProvider>
                <CroppyProvider
                  cid={cid}
                  project={process.env.REACT_APP_FB_PROJECT || 'fitbudd-prod-v1'}
                  s3={{
                    key: process.env.REACT_APP_AWS_KEY,
                    secret: process.env.REACT_APP_AWS_SECRET,
                    bucket: process.env.REACT_APP_AWS_IMAGE_BUCKET,
                    region: process.env.REACT_APP_AWS_REGION,
                  }}>
                  <Router />
                </CroppyProvider>
              </AIProvider>
            </AclProvider>
          </ContractProvider>
        </RoleProvider>
      </ErrorBoundary>
    </Provider>
  );
};

const Fitbud = (props) => {
  if (window.location.pathname === '/welcome') return <Welcome />;
  return (
    <SnackbarProvider preventDuplicate>
      <FirebaseAuthProvider>
        <TagsProvider>
          <Root />
        </TagsProvider>
      </FirebaseAuthProvider>
    </SnackbarProvider>
  );
};

export default Fitbud;
