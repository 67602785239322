import React, { useMemo, useContext } from 'react';
import {
  Typography, Tooltip, IconButton,
  Menu, MenuItem, makeStyles
} from '@material-ui/core';
// import LayersIcon from '@material-ui/icons/LayersOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from 'fitbud/icons/detailEdit';
// import { cardClrCSS } from 'fitbud/views/users/subComponents';
import { formatNo, pluralize } from 'fitbud/utils/helpers';
import { useAnchor } from 'fitbud/hooks/form';
import BMRCard from '../bmr';
import {DurationToHMSComp} from "fitbud/views/workouts/helperfn";
import AddWoCardImg from "fitbud/images/addWoCard.svg";
import AddMlCardImg from "fitbud/images/addMlCard.svg";
import AddWtrCardImg from "fitbud/images/addWtrCard.svg";
import AddActCardImg from "fitbud/images/addActCard.svg";
import {reduce,merge,get,set} from "lodash";
import { UserSchContext } from '../provider';
import clsx from 'clsx';
import { Conversion } from 'fitbud/providers/conversion';
// import LinkIcon from '@material-ui/icons/Link';
// import LinkOffIcon from '@material-ui/icons/LinkOff';
import { LayersIcon } from "fitbud/icons/layersIcon";
import { LinkIcon } from "fitbud/icons/linkIcon";
import { LinkBrokenIcon } from "fitbud/icons/linkBrokenIcon";
import { showActivityMetric } from 'fitbud/views/users/helpers';
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { Draggable } from 'react-beautiful-dnd';

const styles=makeStyles({
  addCardLink:{
    bottom:'10px',
    right:'10px',
    position:'absolute'
  }
})

const MLCard = ({ id, data, className, onClick, future, weekDayMeta, rhs=false, on=false, off=false }) => {
  const { opcopy } = useContext(UserSchContext);
  const disabled = (Boolean(opcopy) || !future);
  const style=useMemo(()=>{
    const css=CSSS['ml'][off ? 'off' : 'on'];
    if(!future) return merge({}, css, CSSS.past);
    return css;
   },[disabled,id]);
  const [l1, l2] = useMemo(() => {
    if (!data) return ['', ''];
    let l2 = '';
    const {macros} = data;
    l2 = formatNo(Math.round(macros.calories||0), {}, '0');
    return [data.title, l2];
  }, [data]);
  const _click = () => {
    if (!onClick) return;
    onClick({ data, id, weekDayMeta, editable: future, rhs, on, off });
  };
  return (
    <div className={`d-flex flex-column justify-content-between px-15 py-10 cursor-pointer ${className || ''}`} style={style} onClick={_click} >
      <Tooltip title={l1}>
        <Typography className='font_13_600 mb-13 mr-20 twoLineEllipsis text-break-all'>{l1}</Typography>
      </Tooltip>
      <div className='d-flex align-items-center justify-content-between'>
        <Typography className='font_12_600'>{l2||"-"}&nbsp;<span className='font-weight-500'>cals</span></Typography>
      </div>
      
    </div>
  );
};

const SLCard = ({
  id,data,className,onClick,future,weekDayMeta,rhs = false,on = false,off = false,sl = false,
}) => {
  const { opcopy } = useContext(UserSchContext);
  const disabled = Boolean(opcopy) || !future;
  const style = useMemo(() => {
    const css = CSSS["sl"][off ? "off" : "on"];
    if (!future) return merge({}, css, CSSS.past);
    return css;
  }, [disabled, id, sl, off, rhs]);
  const _click = () => {
    if (!onClick) return;
    onClick({ data, id, weekDayMeta, editable: future, rhs, on, off });
  };
  return (
    <div
      className={`d-flex flex-column justify-content-between pl-10 py-8 pr-30 cursor-pointer ${
        className || ""
      }`}
      style={style}
      onClick={_click}
    >
      <Tooltip title={data.title||""}>
        <Typography className="flex-1 mb-15 font_13_600 twoLineEllipsis text-break-all">{data.title||""} </Typography>
      </Tooltip>
      <Typography className='font_10_500 text-grey mb-3px'>Supplements</Typography>
      <Typography className="font_12_600">{(data.num_groups)||(data.groups && data.groups.length)||'-'}</Typography>
    </div>
  );
};

const AddWoCard = ({ week, weekId, day, axn, className, future = false, disabled,controller,isToday,isTomorrow}) => {
  const classes=styles();
  const isMaster=useMemo(()=>{
    return controller.isMasterApplied({weekId,week,day,future});
  },[week,day,future]);
  if (!future) return <div style={CSSS.fake} />;
  const attributes=isToday ? ({id:'WoToday'}):(isTomorrow? {id:'WoTomorrow'}:{});
  return (
    <div  {...attributes} className={clsx('d-flex align-items-center mr-15 p-15 position-relative',className,!disabled && 'cursor-pointer')}
      data-week={week} data-weekid={weekId} data-day={day} data-purpose='fitness' onClick={!disabled && axn} style={CSSS.addCard}>
      {isMaster && <Link on={!isMaster.overridden} className={classes.addCardLink}/>}
      <img src={AddWoCardImg} className="mr-15"/>
      <Typography className='font_13_600 text-0d0d0d'>Add Workout</Typography>
    </div>
  );
};
//NEW CARDS-new UI

const AddMlCard = ({ week, weekId, day, axn, className, future = false, disabled, controller, index, isToday, isTomorrow }) => {
  const {local}=useContext(UserSchContext);
  const classes=styles();
  const isMaster=useMemo(()=>{
    return controller.isMasterApplied({weekId,week,day,type:'ml',future});
  },[week,day,future,get(local,['weeks',weekId,'data','d'+(day+1)])]);
  if (!future) return <div style={CSSS.fake} />;
  const attributes=isToday ? ({id:'MlToday'}):(isTomorrow? {id:'MlTomorrow'}:{});
  return (
    <Draggable draggableId={day + ':' + 'ml' + ':' + 'add'} index={index} isDragDisabled={true}>
    {(provided)=>(
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <div  {...attributes} className={clsx('d-flex align-items-center mr-15 p-15 position-relative',className,!disabled && 'cursor-pointer')}
        data-week={week} data-weekid={weekId} data-day={day} data-purpose='nutrition' onClick={!disabled && axn} style={CSSS.addCard}>
          {isMaster && <Link on={!isMaster.overridden} className={classes.addCardLink}/>}
          <img src={AddMlCardImg} className="mr-15"/>
          <Typography className='font_13_600 text-0d0d0d'>Add Meal</Typography>
        </div>       
      </div>
    )}
    </Draggable>
  );
};
const AddWaterCard=(props)=>{
  const { week, weekId, day, axn, className, disabled}=props;
  return(
    <div className={clsx('d-flex align-items-center mr-15 p-15',className,!disabled && 'cursor-pointer')}
      data-week={week} data-weekid={weekId} data-day={day} data-purpose='water' onClick={!disabled && axn} style={CSSS.addCard}>
      <img src={AddWtrCardImg} className="mr-15"/>
      <Typography className='font_13_600 text-0d0d0d'>Add Water</Typography>
    </div>
  )
}
const AddActCard=(props)=>{
  const { week, weekId, day, axn, className, disabled = false }=props;
  return(
    <div className={clsx('d-flex align-items-center mr-15 p-15',className,!disabled && 'cursor-pointer')}
      data-week={week} data-weekid={weekId} data-day={day} data-purpose='activity' onClick={!disabled && axn} style={CSSS.addCard}>
      <img src={AddActCardImg} className="mr-15"/>
      <Typography className='font_13_600 text-0d0d0d'>Add Activity</Typography>
    </div>
  )
}
const WaterCard = (props) => {
  const { week, weekId, day, axn, className, future = false, disabled }=props;
  const { local, scheduleAggrs:aggrs,weekToday } = useContext(UserSchContext);
  const { convertor } = useContext(Conversion);
  const style=useMemo(()=>{
   if(!future) return merge({},CSSS.water,CSSS.past);
   return CSSS.water;
  },[disabled]);
  const water=useMemo(()=>{
    if(!!future){
      return get(local, ['plan', 'wc']) || {};
    }
    else{
      const _day="day_"+((week*7)+day+1);
      const water={
        required:!!get(aggrs,['water_target',_day]),
        value:get(aggrs,['water_target',_day,'total'],0)
      };
      return water;
    }
  },[future,local,weekId])
  return (
    <>
    {((!water.required||!water.value) && !!future) && <AddWaterCard  {...props}/>}
    {!!water.required && !!water.value && (<div className={clsx("px-15 py-10 mr-15",className,!disabled && "cursor-pointer")} style={style}  onClick={!disabled && axn}
      data-week={week} data-weekid={weekId} data-day={day} data-purpose='water'>
        <Typography className='font_13_600 mb-14'>Water</Typography>
        <Typography className='text-grey font_10_500'>{convertor.getDisplayUnit('water')}</Typography>
        <Typography>
        <span className='font_12_600'>
          {convertor.getDisplayValue("water",water.value||0)||"-"}
        </span>
      </Typography>
    </div>)}
    </>
  );
};
const ActivityCard=(props)=>{
  const { comp } = useContext(FirebaseAuthContext);
  const app_config_activities=comp && get(comp.data(),'app_config.activities');
  const { week, weekId, day, axn, className, future = false, disabled } = props;
  const { local, scheduleAggrs:aggrs,weekToday } = useContext(UserSchContext);
  const { convertor } = useContext(Conversion);
  const style=useMemo(()=>{
    if(!future) return merge({},CSSS.activity,CSSS.past);
    return CSSS.activity;
   },[disabled]);
  const activity=useMemo(()=>{
    if(!!future){
      return get(local, ['plan', 'ac'], {})||{};
    }
    else{
      const _day="day_"+( week * 7 + day + 1 );
      const activity={
        required:!!get(aggrs,['activity_target',_day]),
        value:get(aggrs,['activity_target',_day])
      };
      return activity||{};
    }
    },[future,local,weekId]);
  const [showSteps,showDis,showFlights,showEnergy]=useMemo(()=>{
    return showActivityMetric(app_config_activities);
  },[app_config_activities]);
  const {steps,distance:d,flights,energy}=activity.value||{};
  const distance=convertor.getDisplayValue("distance",d||0);
  return(
    <>
      {((!activity.required||!steps) && !!future) && <AddActCard {...props}/>}
      {(!!activity.required && !!steps) && (
          <div className={clsx("px-15 py-10",className,!disabled && "cursor-pointer")} style={style}  onClick={!disabled && axn}
            data-week={week} data-weekid={weekId} data-day={day} data-purpose='activity'>
          <Typography className='font_13_600 mb-14'>Activity</Typography>
            <div className='d-flex justify-content-between'>
        {!!steps && showSteps && <div style={{width:50}}>
          <Typography className='text-grey font_10_500'>steps</Typography>
          <span className='font_12_600'>{steps||"-"}</span>
        </div>}
        {!!distance && showDis && <div style={{width:50}}>
          <Typography className='text-grey font_10_500'>{convertor.getDisplayUnit('distance')}</Typography>
          {distance?<span className='font_12_600'>{distance}</span> :"-"}
        </div>}
        {!!flights && showFlights && <div style={{width:50}}>
          <Typography className='text-grey font_10_500'>floors</Typography>
          {flights?<span className='font_12_600'>{flights}</span> :"-"}
        </div>}
        {!!energy && showEnergy && <div style={{width:50}}>
          <Typography className='text-grey font_10_500'>cals</Typography>
          {energy?<span className='font_12_600'>{energy}</span> :"-"}
        </div>}
      </div>
    </div>
    )}</>
  )
}

const WOCard = ({ id, index, data, alts, className, onClick, future, weekDayMeta, addAlt }) => {
  const { opcopy } = useContext(UserSchContext);
  const disabled = (Boolean(opcopy) || !future);
  const style=useMemo(()=>{
    if(!future) return merge({},CSSS.wo,CSSS.past);
    return CSSS.wo;
   },[disabled]);
  const [l1, l2] = useMemo(() => {
    if (!data) return ['', '-'];
    const {ref_name, duration} = data;
    return [ref_name || '',duration];
  }, [data]);
  const handleClick = () => {
    if (alts > 0) {
      addAlt({
        currentTarget: {
          dataset: {
            week: weekDayMeta.week.toString(),
            weekid: weekDayMeta.weekId,
            day: weekDayMeta.day,
            purpose: 'altWo',
            id,
            index
          },
        }
      });
      onClick({ weekDayMeta, editable: future })
    } else {
      const details = { data, id }
      if (weekDayMeta) details.weekDayMeta = weekDayMeta;
      if (future) details.editable = future;
      onClick(details)
    }
  }
  return (
    <div className={`d-flex flex-column justify-content-between px-15 py-10 ${className || ''}`} style={style} onClick={handleClick}>
      <Tooltip title={l1}>
        <Typography className='font_13_600 overflow-hidden text-0d0d0d mr-20 twoLineEllipsis'> 
        {/* text-break-all removed */}
          {l1}
          {!!alts && ` + ${alts} Alts`}
        </Typography>
      </Tooltip>
      <div className='d-flex flex-row align-items-center text-dark-grey'>
        <Typography className='font_12_500' color='inherit'>
          <DurationToHMSComp duration={l2}/>
        </Typography>
      </div>
    </div>
  );
};
const RestCard=({className,onClick=null})=>{
  return(
    <div className={clsx('d-flex justify-content-center align-items-center',className,onClick && 'cursor-pointer')} style={CSSS.rest} data-type='rest' onClick={onClick}>
      <Typography className='font_13_600' data-type='rest'>Rest Day</Typography>
    </div>
  )
}

const ItemCard = ({ id, index, weekId, week, day, dayCopy, objCache, controller, type, ftAltWOs=false, disabled=false, on=false, off=false, future = false, rhs = false, className }) => {
  const [menu, openMenu, closeMenu] = useAnchor();
  const {local,bmrlocal}=useContext(UserSchContext);
  const isWoMaster=useMemo(()=> controller.isMasterApplied({weekId,week,day,future}),[get(local,['weeks',weekId,'data','d'+(day+1)])]);
  const isMlMaster=useMemo(()=> controller.isMasterApplied({weekId,week,day,type:"ml",future}),[get(local,['weeks',weekId,'data','d'+(day+1)])]);
  const isSlMaster=useMemo(()=> controller.isMasterApplied({weekId,week,day,type:"sl",future}),[get(local,['weeks',weekId,'data','d'+(day+1)])]);
  const showManage=useMemo(()=> !!future && ((isWoMaster && (type==="wo"||type==="rest")) || (isMlMaster && type==="ml")|| (isSlMaster && type==='sl')),[isWoMaster,isMlMaster,isSlMaster,type]);
  const [data, alts] = useMemo(() => {
    if (!id) return [null, false];
    if (id === 'rest') return ['rest', false];
    const parts = id.split('/');
    const x = parts[0];
    if (future) return [((objCache && objCache[x]) || (dayCopy && dayCopy[x])), parts.length - 1];
    return [dayCopy && dayCopy[x], parts.length - 1];
  }, [id, future, dayCopy, objCache]);
  if (!id || id === 'none') return null;
  // if (!data) return null;

  const removeMain = () => {
    if(type==="sl"){
      controller.changeMasters(type, week, null, day,false);
    }
    else {
      controller.removeFromDay(weekId, week, day, id, type);
    }
    closeMenu();
  };
  const copyItem = () => {
    closeMenu();
    controller.copySingleItem(weekId, day, type, id);
  };
  const addAlt = (e) => {
    closeMenu();
    controller.importClick(e);
  };
  const manageSchedule = (e) =>{
    const {dataset} = e.target
    const {type}=dataset;
    const _e={};
    if(type==="wo"||type==='rest'){
      set(_e,['currentTarget','dataset'],{
        'week':String(week),'weekid':weekId,'day':String(day),
        'purpose':'fitness'
      });
    }
    else if(type==="ml"){
      set(_e,['currentTarget','dataset'],{
        'week':String(week),'weekid':weekId,'day':String(day),
        'purpose':'nutrition'
      });
    }
    else if(type==='sl'){
      set(_e,['currentTarget','dataset'],{
        'week':String(week),'weekid':weekId,'day':String(day),
        'purpose':'slms'
      });
    }
    closeMenu();
    controller.importClick(_e);
  }
  const exception=id==="bmr" && !!future;
  
  if (!data && !exception) return null;
  const modified =data && (data.edited || id.startsWith('modified_')) && data.archive;
  return (
    <div className={`position-relative mr-15`}>
      {!disabled && !rhs && type!=="rest" && (id==="bmr"?!!showManage:true) && !(type==='sl' && !future) && <IconButton size='small' style={{top:4,right:4}} className='position-absolute' onClick={openMenu}><MoreVertIcon/></IconButton>}
      {menu && <Menu open anchorEl={menu} onClose={closeMenu} MenuListProps={{dense: true, disablePadding: true}} getContentAnchorEl={null} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        {(type!=='sl' && id!=="bmr") && <MenuItem onClick={copyItem}>Copy</MenuItem>}
        {(future && id!=="bmr") &&  <MenuItem onClick={removeMain}>Delete</MenuItem>}
        {ftAltWOs && future && type !== 'ml' && <MenuItem data-purpose='altWo' data-weekid={weekId} data-week={week} data-day={day}
        data-index={index} data-id={id} onClick={addAlt}>{alts ? 'Manage' : 'Add'} Alternatives</MenuItem>}
        {showManage && <MenuItem onClick={manageSchedule} data-type={type}> Manage schedule </MenuItem>}
        {/* {type==="sl" && <MenuItem onClick={manageSchedule} data-type={type}>Replace</MenuItem>} */}
      </Menu>}
      <div className='position-absolute d-flex' style={{bottom:7,right:10}}>
        {(isWoMaster && (type==="wo"||type==="rest")) && <Link on={!isWoMaster.overridden} className="ml-8"/>}
        {(isMlMaster && type==="ml") && <Link on={!isMlMaster.overridden} className="ml-8"/>}
        {(isSlMaster && type==="sl") && <Link on={!isSlMaster.overridden} className="ml-8"/>}
        {!!ftAltWOs && !!alts && <Tooltip title="Alternates present">
          <div className="ml-8">
            <LayersIcon/>
          </div>
        </Tooltip>}
        {data && !alts && modified && <Tooltip title='Modified for this client'><div
          className='ml-8'><EditIcon color='#A2AEBF' fontSize='inherit'/>
          </div>
        </Tooltip>}
      </div>
      {type==="rest" && <RestCard className={className} future={future} onClick={manageSchedule}/>}
      {id==="bmr" && <BMRCard future={future} data={data}/>}
      {id!=="bmr" && type === 'ml'  && <MLCard id={id} rhs={rhs} data={data} on={on} off={off} className={className} sl={type === 'sl'} future={future} onClick={controller.showDetail} weekDayMeta={{ weekId, week, day }}/> }
      {type ==="wo" &&  <WOCard id={id} index={index} data={data} className={className} alts={ftAltWOs && alts} onClick={controller.showDetail} future={future} weekDayMeta={{ weekId, week, day }} addAlt={addAlt}/>}
      {type==="sl" && <SLCard id={id} rhs={rhs} data={data} on={on} off={off} className={className} sl={type === 'sl'} future={future} onClick={controller.showDetail} weekDayMeta={{ weekId, week, day }}/> }
    </div>
  );
};

export const CSSS = {
  ml: {
    on: { height: 80, width: 210, backgroundColor: '#EBF7EB', border: '1px solid #05B715',  borderRadius: 15 },
    off: { height: 80, width: 210, backgroundColor: '#FFFFFF', border: '1px solid #05B715', borderRadius: 15 },
  },
  sl: {
    on: { height: 80,width: 210,  backgroundColor: '#FFFCF2', border: '1px solid #ECBC05', borderRadius: 15 },
    off: { height: 80,width: 210, backgroundColor: '#FFFFFF', border: '1px solid #ECBC05', borderRadius: 15  },
  },
  wo: { height: 80, width: 210, backgroundColor: '#FFF4F2', border: '1px solid #F14E3F', borderRadius: 15, cursor:"pointer"},
  rest: { height: 80, width: 210, border: '1px solid #D3D9DB', borderRadius: 15, overflow: 'hidden', backgroundColor: '#F0F3F5' },
  addCard: { height: 80, width: 210, border: '1px solid #D3D9DB', borderRadius: 15 },
  water: { height: 80, width: 140, border: '1px solid #3394D9', borderRadius: 15 , backgroundColor:'#F2FAFF'},
  activity: { height: 80, width: 'fit-content',minWidth:'140px', border: '1px solid #FF9A24', borderRadius: 15 , backgroundColor:'#FFFAF3'},
  fake: { height: 80, width: 0 },
  past: { backgroundColor:"unset"} 
};
// const CSSRAND = (id, meal = false) => {
//   const clrs = cardClrCSS(id, meal);
//   return { height: 80, width: 210, borderRadius: 15, ...clrs };
// };
const Link=({on=true,...rest})=>{
  const title=on?"Linked Master Schedule":"Master Schedule Broken";
  return(
    <Tooltip title={title}>
      <div {...rest}>
        { on? <LinkIcon/> :<LinkBrokenIcon/>}
      </div>
    </Tooltip>
  )
}
export {
  MLCard, SLCard, WOCard, ItemCard,
  AddWoCard,
  AddMlCard,
  WaterCard,
  ActivityCard
};
