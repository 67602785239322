import React, { useContext, useReducer, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { ContractContext } from 'fitbud/providers/contract';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { oprtnlDateFormat } from 'fitbud/utils/constants';
import { RoleContext } from './roleProvider';
import { bffGetContractIssues } from 'fitbud/api';
import useAPI from 'fitbud/hooks/useAPI';

const ACL_CONTEXT = React.createContext({});
export const useAcl = () => useContext(ACL_CONTEXT);

export const ACL_STATUS = {
  TRIAL: 'in_trial',
  TRIAL_GRACE: 'in_trial_grace',
  TRIAL_LIMITED: 'trial_limited',
  PLAN_GRACE: 'plan_grace',
  PLAN_LIMITED: 'plan_limited',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
};

export const PLANS = {
  SILVER: 'Silver', //initiate
  BASIC_GOLD: 'Pro', //  pro
  GOLD: 'Gold', // super  pro
  PLATINUM: 'Platinum', //elite
  TRIAL: 'Trial',
};

//gold basic == pro,
const nextPlan = (plan) => {
  const next = {
    [PLANS.TRIAL]: PLANS.SILVER,
    [PLANS.SILVER]: PLANS.BASIC_GOLD,
    [PLANS.BASIC_GOLD]: PLANS.GOLD,
    [PLANS.GOLD]: PLANS.PLATINUM,
  };
  return next[plan];
};

const DEFAULT_FEATURE_TYPE = 'licensed';

export const ACL_FEATURES_KEY = {
  TRAINERS: 'trainers',
  GROUP_CHAT: 'group_chat',
  BROADCAST: 'broadcasts',
  THEME_BUILDER: 'theme_builder',
  CONTENT_UPLOAD: 'content_upload',
  CUSTOM_DOMAIN: 'custom_domain',
  AUTOMATION: 'automation',
  EXPLORE: 'explore',
  RESOURCES: 'resources',
  VIDEO_CALLING: 'video_calling',
  IN_APP_MESSAGING: 'in_app_messaging',
  IN_APP_NOTIFICATION: 'in_app_notifications',
  GROUP_CLASS: 'group_class',
  WEBSITE: 'website',
  PAYMENTS_PLAN: 'payment_plan',
  MAILCHIMP: 'mailchimp',
  APP_THEME: 'app_theme',
  CHALLENGES: 'challenges',
};

export const SHOW_ADDONS = {
  [PLANS.GOLD]: [ACL_FEATURES_KEY.TRAINERS, ACL_FEATURES_KEY.GROUP_CLASS, ACL_FEATURES_KEY.EXPLORE],
  [PLANS.BASIC_GOLD]: [ACL_FEATURES_KEY.TRAINERS, ACL_FEATURES_KEY.GROUP_CLASS, ACL_FEATURES_KEY.EXPLORE],
};

export const ACL_CONFIGURATION = {
  trainers: {
    key: ACL_FEATURES_KEY.TRAINERS,
    name: 'Teams',
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER, PLANS.TRIAL],
    addOn: [PLANS.GOLD, PLANS.BASIC_GOLD],
    newEndDate: '20240930',
  },
  group_chat: {
    key: ACL_FEATURES_KEY.GROUP_CHAT,
    name: 'Group Chat',
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.TRIAL, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
  },
  app_theme: {
    key: ACL_FEATURES_KEY.APP_THEME,
    name: 'App Theme',
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.TRIAL, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
    addOns: [],
  },
  broadcasts: {
    key: ACL_FEATURES_KEY.BROADCAST,
    name: 'Broadcast',
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.TRIAL, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
  },
  theme_builder: {
    key: ACL_FEATURES_KEY.THEME_BUILDER,
    name: 'Customize Theming',
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.TRIAL, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
  },
  content_upload: {
    key: ACL_FEATURES_KEY.CONTENT_UPLOAD,
    name: 'Content Upload',
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.TRIAL, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
  },
  custom_domain: {
    key: ACL_FEATURES_KEY.CUSTOM_DOMAIN,
    name: 'Custom Domain',
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
  },
  automation: {
    key: ACL_FEATURES_KEY.AUTOMATION,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    name: 'Plan Automation',
    plans: [PLANS.TRIAL, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
  },
  explore: {
    // plans: [],
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    key: ACL_FEATURES_KEY.EXPLORE,
    plans: [PLANS.TRIAL, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
    addOn: [PLANS.GOLD, PLANS.BASIC_GOLD],
    name: 'On Demand Content',
    newEndDate: '20240930',
  },
  resources: {
    key: ACL_FEATURES_KEY.RESOURCES,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.TRIAL, PLANS.BASIC_GOLD, PLANS.GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
    name: 'Resources',
  },
  video_calling: {
    key: ACL_FEATURES_KEY.VIDEO_CALLING,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.TRIAL, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.PLATINUM],
    excludePlans: [PLANS.SILVER],
    name: 'One to one Calling',
    type: 'metered',
    limits: {
      [PLANS.GOLD]: 10,
      [PLANS.PLATINUM]: -1, // unlimited
    },
  },
  in_app_messaging: {
    //to do review
    key: ACL_FEATURES_KEY.IN_APP_MESSAGING,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.PLATINUM],
    name: 'In app messaging',
    excludePlans: [PLANS.SILVER, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.TRIAL],
  },
  in_app_notifications: {
    key: ACL_FEATURES_KEY.IN_APP_NOTIFICATION,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.PLATINUM],
    name: 'In app notification',
    excludePlans: [PLANS.SILVER, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.TRIAL],
  },
  group_class: {
    key: ACL_FEATURES_KEY.GROUP_CLASS,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.TRIAL, PLANS.PLATINUM],
    addOn: [PLANS.GOLD, PLANS.BASIC_GOLD],
    excludePlans: [PLANS.SILVER],
    name: 'Group Class',
    newEndDate: '20240930',
  },
  website: {
    key: ACL_FEATURES_KEY.WEBSITE,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    name: 'Website',
    plans: [PLANS.TRIAL, PLANS.GOLD, PLANS.SILVER, PLANS.PLATINUM, PLANS.BASIC_GOLD],
    excludePlans: [],
  },
  mailchimp: {
    key: ACL_FEATURES_KEY.MAILCHIMP,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.GOLD, PLANS.PLATINUM, PLANS.BASIC_GOLD],
    name: 'Mail Chimp',
    excludePlans: [PLANS.SILVER, PLANS.TRIAL],
  },
  challenges: {
    key: ACL_FEATURES_KEY.CHALLENGES,
    components: [],
    pages: [],
    owner: false,
    sudo: true,
    plans: [PLANS.PLATINUM],
    name: 'Challenges',
    excludePlans: [PLANS.SILVER, PLANS.GOLD, PLANS.BASIC_GOLD, PLANS.TRIAL],
  }
};

const INITIAL_STATE = {
  status: ACL_STATUS.ACTIVE,
};

const getStatusKey = (res) => {
  const { status, overdue, limited } = res || {};
  if (status === 'trial') {
    if (limited) return ACL_STATUS.TRIAL_LIMITED;
    if (overdue) return ACL_STATUS.TRIAL_GRACE;
    return ACL_STATUS.TRIAL;
  }
  if (status === 'activated') {
    if (limited) return ACL_STATUS.PLAN_LIMITED;
    if (overdue) return ACL_STATUS.PLAN_GRACE;
    return ACL_STATUS.ACTIVE;
  }
  if (status === 'cancelled') {
    return ACL_STATUS.CANCELLED;
  }
  return ACL_STATUS.ACTIVE;
};

const reducer = (os, ns) => ({ ...os, ...ns });

const AclProvider = (props) => {
  const [state, setState] = useReducer(reducer, INITIAL_STATE);
  const { contract, loading, error: contractError } = useContext(ContractContext);
  const { isTrainerRole, isStaffRole } = useContext(RoleContext);
  const { comp, cid } = useContext(FirebaseAuthContext);
  const { data: contractIssue, load: loadContractIssue } = useAPI(bffGetContractIssues);
  const companyData = !!comp ? comp.data() : {};
  const features = companyData?.features;
  const isExplore = companyData._next;
  const force_allow = _.get(contract, 'force_allow', {});
  const { status, limited, overdue, startDate, trial_duration = 30, blockDate, endBlockDate = null } = contract || {};
  const planId =
    contract?.meta?.future?.internal_name ||
    contract?.meta?.future?.prodName ||
    contract?.meta?.current?.internal_name ||
    contract?.meta?.current?.prodName;
  const { status: activeStatus, trial_left } = state;

  useEffect(() => {
    if ([ACL_STATUS.PLAN_GRACE, ACL_STATUS.PLAN_LIMITED].includes(activeStatus)) {
      loadContractIssue([cid], (resp) => {
        return _.get(resp, 'data');
      });
    }
  }, [activeStatus, cid]);

  const manageSubscriptionUrl = useMemo(() => {
    const launch = _.get(contractIssue, 'launch');
    return launch;
  }, [contractIssue]);

  const plan = useMemo(() => {
    if (status === 'trial') return PLANS.TRIAL;
    switch (planId) {
      case PLANS.PLATINUM:
        return PLANS.PLATINUM;
      case PLANS.GOLD:
        return PLANS.GOLD;
      case PLANS.BASIC_GOLD:
        return PLANS.BASIC_GOLD;
      case PLANS.SILVER:
        return PLANS.SILVER;
    }
    //default return silver:
    return PLANS.SILVER;
  }, [planId, status, ACL_STATUS, PLANS, limited, overdue]);
  /*
  Acl status and logic will go here
  */
  useEffect(() => {
    const _status = getStatusKey({ status, limited, overdue });
    const out = { status: _status };
    if (_status === ACL_STATUS.TRIAL) {
      const daysPassed = moment().diff(moment.unix(startDate._seconds), 'days');
      const diff = Math.max(trial_duration - daysPassed, 0);
      out.trial_left = diff;
    }
    setState(out);
  }, [status, limited, overdue]);

  const checkForForcedBlock = () => {
    if(contractError) throw new Error(contractError);
    let forcedBlock = false;
    if (blockDate) {
      forcedBlock = moment().isAfter(moment(blockDate, oprtnlDateFormat), 'day') && (!endBlockDate || moment().isBefore(moment(endBlockDate, oprtnlDateFormat), 'day'));
    }
    if (!loading && !_.isEmpty(contractError)) forcedBlock = true;
    if (!loading && _.isElement(contract)) forcedBlock = true;
    const isTrainerFeatureEnabled = checkAccessFeature(status, ACL_FEATURES_KEY.TRAINERS, 'test');
    if (!!isTrainerRole && !isTrainerFeatureEnabled) forcedBlock = true;
    if (!!isStaffRole && !isTrainerFeatureEnabled) forcedBlock = true;
    return forcedBlock;
  };

  const checkLimitedAccess = useCallback(
    (_status = activeStatus) => {
      let forcedBlock = checkForForcedBlock();
      if (
        _status === ACL_STATUS.TRIAL_LIMITED ||
        _status === ACL_STATUS.PLAN_LIMITED ||
        _status === ACL_STATUS.CANCELLED ||
        forcedBlock
      )
        return true;
      return false;
    },
    [blockDate, status, loading, isTrainerRole, contract, contractError]
  );

  const checkShowUpgrade = useCallback(
    (status, fkey) => {
      return false; //removing from now
      // if (loading) return false;
      // const feature = ACL_CONFIGURATION[fkey];
      // if (!feature) return false;
      // if (feature.plans.includes(plan)) return false;
      // if (feature.excludePlans && feature.excludePlans.includes(plan)) return true;
      // if (feature.addOn && feature.addOn.includes(plan)) {
      //   return !(contract?.meta?.addOnsCurrent && contract?.meta?.addOnsCurrent[fkey]?.value);
      // }
      // if (feature.plans && feature.plans.includes(nextPlan(plan))) return true;
      // return false;
    },
    [plan, contract, loading]
  );

  const checkAccessFeature = useCallback(
    (status, fkey) => {
      if (loading) return true; //if in loading state allow
      if (status === ACL_STATUS.CANCELLED) return false;
      const feature = ACL_CONFIGURATION[fkey];
      if (!feature) return true;
      if(!!_.get(force_allow,fkey)) return true;
      if (feature.excludePlans && feature.excludePlans.includes(plan)) return false;
      if (feature.plans && feature.plans.includes(plan)) {
        return true;
        // return contract?.meta?.addOnsCurrent && contract?.meta?.addOnsCurrent[fkey]?.value;
      }
      if (feature.addOn && feature.addOn.includes(plan)) {
        // check if the feature is enabled in contract or not and return true or false.
        return contract?.meta?.addOnsCurrent && contract?.meta?.addOnsCurrent[fkey]?.value;
      }
      return false;
    },
    [plan, contract, loading, force_allow]
  );

  const checkShowFeature = useCallback(
    (fkey) => {
      //handling old companies, which has disabled the features.
      // if (fkey === ACL_FEATURES_KEY.GROUP_CLASS) {
      //   if (checkAccessFeature(status, fkey)) {
      //     return !!_.get(features, 'group_class.enabled');
      //   }
      // }
      if (fkey === ACL_FEATURES_KEY.GROUP_CHAT) {
        if (checkAccessFeature(status, fkey)) {
          return !!_.get(features, 'group_chat');
        }
      }
      // if (fkey === ACL_FEATURES_KEY.EXPLORE) {
      //   if (checkAccessFeature(status, fkey)) {
      //     return !!isExplore;
      //   }
      // }
      // if (fkey === ACL_FEATURES_KEY.TRAINERS) {
      //   if (checkAccessFeature(status, fkey)) return !!_.get(features, 'trainers');
      // }
      if (fkey === ACL_FEATURES_KEY.VIDEO_CALLING) {
        if (checkAccessFeature(status, fkey)) return !!_.get(features, 'video_calling');
      }
      //for new plans....
      if (plan === PLANS.PLATINUM) return true; //if platinum then show all.
      if (plan !== PLANS.PLATINUM) {
        //only showing in platinum
        if ([ACL_FEATURES_KEY.IN_APP_NOTIFICATION].includes(fkey)) return false;
        if ([ACL_FEATURES_KEY.CHALLENGES].includes(fkey)) return false;
      }

      if ((plan === PLANS.TRIAL || plan === PLANS.SILVER) && fkey === ACL_FEATURES_KEY.MAILCHIMP) {
        //only showing in platinum
        return false;
      }
      if (plan === PLANS.TRIAL) {
        //not showing in trial..
        if ([ACL_FEATURES_KEY.TRAINERS, ].includes(fkey)) return false;
      }
      return true;
    },
    [plan, contract, loading, isExplore, features, checkAccessFeature, status]
  );

  //methods for check upgrade or checkout action required..
  const checkUpgradeOrAddOn = (fkey) => {
    const feature = ACL_CONFIGURATION[fkey];
    if (!feature) {
      window.location.href = `/config/billing`;
      return;
    }
    let upgrade = false;
    if (feature.addOn && feature.addOn.includes(plan)) upgrade = false;
    else upgrade = true;
    return upgrade;
  };
  
  const checkTrialAvailable = (fkey) => {
   return ACL_CONFIGURATION[fkey]?.addOn?.length && !contract?.meta.addOnsPast?.[fkey] && !contract?.meta.addOnsCurrent?.[fkey] && !contract?.meta.addOnsFuture?.[fkey] && plan !== PLANS.TRIAL;
  } 
  const checkPreviouslyBoughtAddOn = (fkey) => {
    return ACL_CONFIGURATION[fkey]?.addOn?.length && (contract?.meta.addOnsPast?.[fkey] || contract?.meta.addOnsCurrent?.[fkey] || contract?.meta.addOnsFuture?.[fkey]);
  }
  const checkTrialExpired = (fkey) => {
    return ACL_CONFIGURATION[fkey]?.addOn?.length && contract?.meta.addOnsPast?.[fkey]?.trialExpired
  }
  const isNew = (fkey) => {
    const newEndDate = ACL_CONFIGURATION[fkey]?.newEndDate;
    if(!newEndDate || plan === PLANS.PLATINUM || !contract) return false;
    return moment().isBefore(moment(newEndDate, 'YYYYMMDD')) && !contract?.meta.addOnsPast?.[fkey] && !contract?.meta.addOnsCurrent?.[fkey] && !contract?.meta.addOnsFuture?.[fkey];
  }
  
  const isNewFeatures = (fkeys) => {
    // check if fkeys is string then convert it to array
    if(typeof fkeys === 'string') fkeys = [fkeys];
    return fkeys.reduce((acc, fkey) => {
      return acc || isNew(fkey);
    }, false);
  }

  return (
    <ACL_CONTEXT.Provider
      value={{
        status: activeStatus,
        contract,
        trial_left,
        activePlan: plan,
        manageSubscriptionUrl,
        checkLimitedAccess,
        checkAccessFeature,
        checkShowUpgrade,
        checkShowFeature,
        checkUpgradeOrAddOn,
        checkForForcedBlock,
        checkTrialAvailable,
        checkPreviouslyBoughtAddOn,
        checkTrialExpired,
        isNewFeatures,
      }}>
      {props.children}
    </ACL_CONTEXT.Provider>
  );
};

export default AclProvider;
