import {
  FormControlLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography
} from "@material-ui/core";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import {AltWo} from "fitbud/views/workouts/components";
import Dialog from "fitbud/components/Dialog";
import { ClrdButton, FormTextField } from "fitbud/components/form-fields";
import ImageFileUpload from "fitbud/components/imageSelector";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import Confirmation from "../../components/confirmationDialog";
import { TagsContext } from 'fitbud/providers/tagsProvider';
import { protectNumberInputs, roundMacros } from "fitbud/utils/helpers";
import MinMaxField from "fitbud/components/minMaxField";
import AutoAwesomeIcon from '@material-ui/icons/Assistant';
import { useAI, AI_FORMS } from 'fitbud/providers/ai-provider';

const SPCL = ['silbe', 'barmas', 'burnfatandfeast', 'pfree', 'hfggx1nkxm', 'tasdeekraza123', 'laura252', 'ferneyfitness', 'abhinavmahajan'];

const CreateEditForm = (props) => {
  const {
    doc,
    errors,
    loading,
    isNew,
    handleChange,
    handleSingleVideo,
    onCancel,
    onSubmit,
    handleDraft,
    handlePublish,
    draftFeature,
    isDraftAvailable
  } = props;
  const {cid, comp} = useContext(FirebaseAuthContext);
  let app_config=useMemo(()=>{
    if(comp && comp.exists && comp.data){
      return comp.data().app_config||{};
    }
    return {};
  },[comp])

  const [open, setOpen] = useState(true);
  const { toggleChat } = useAI();

  const triggerAIForm = () => {
    toggleChat({ formData: { type: AI_FORMS.WORKOUT }, });
    setOpen(false);
    onCancel();
  }
  const [isConfirmationOpen, showConfirmation] = useState(null);
  const [dirty, setDirty] = useState(false);//to show leave-confirmation 
  const { woSubTypes, fetchTags } = useContext(TagsContext);
  const subTypes = useMemo(() => { // for backwards compatibility
    if (!woSubTypes) return [];
    return woSubTypes.map(([id, { value }], n) => {
      return {value: id, index: n, label: value};
    });
  }, [woSubTypes]);
  //---common states end----
 
  // const [isDurationDirty,setDurationDirty]=useState(doc.is_single_video?(doc.duration!==doc.total_duration):false);
  // const [canUseVideoThumbnail,allowUseVideoThumbnail]=useState(!!isNew);
  // const [isFrameSelectorOpen,toggleFrameSelector]=useState(false);
  // const [isThumbnailLoading,toggleThumbnailLoading]=useState(false);
  const supportsAltWo = Boolean(_.get(app_config, "tags.altwo", false));
  const altWo = _.get(app_config, "tags.altwo", []);
  
  useEffect(() => {
    fetchTags('woSubTypes');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAltWo = (changes) =>{
    setDirty(true);
    Object.entries(changes).forEach(([name, value]) => {
      handleChange({target: { name, value }});
    });
  }

  
  const handleChangeWrapper = (e) => {
    const { name, value } = e.target;
    setDirty(true);
    if (name === "is_single_video"){
      if (isNew) handleSingleVideo(e);
      else handleChange({target: {name: 'intro', value: value == '2' }}); // eslint-disable-line eqeqeq
    } 
    else if(name==="thumbnail"){
      handleChange(e);
    }
    else handleChange(e);
  };

  const openConfirmationDialog = () => {
    if (dirty) {
      showConfirmation('cancel');
    } else {
      onCancel();
    }
  };
  const handleSubmit=()=>{
    if(!dirty && !isNew && !isDraftAvailable){
      onCancel();
      return;
    }
    if(isNew || !draftFeature){
      onSubmit();
    }
    else{
      showConfirmation('save');
    }
  }

  return (
    <>
      <Dialog
        toolbarClass="height-60"
        buttonColor="primary"
        open={open}
        onClose={openConfirmationDialog}
        onSave={handleSubmit}
        title={isNew ? "Add Workout" : "Edit Workout"}
        titleFont="h3"
        paperClass={"width-600"}
        actionText={isNew && "Next"}
        appBarColor={"bg-grey-new"}
        additionalActions={isNew && <ClrdButton onClick={triggerAIForm} variant="contained" style={{ margin: '10px' }}>{<AutoAwesomeIcon></AutoAwesomeIcon>}{ "Generate with FitBot" }</ClrdButton>}
      >
        <div className="fp-20">
          <Step1
            cid={cid}
            isNew={isNew}
            doc={doc}
            woSubTypes={subTypes}
            errors={errors}
            loading={loading}
            handleChange={handleChangeWrapper}
            handleSingleVideo={handleSingleVideo}
            supportsAltWo={supportsAltWo}
            handleAltWo={handleAltWo}
            altWo={altWo}
          />
          {isConfirmationOpen && (
            <>
              {(isNew || !draftFeature)? (
                <Confirmation
                  open
                  handleChange={onCancel}
                  handleCancel={() => showConfirmation(null)}
                />
              ) : (
                <CustomConfirmation
                  handleClose={() => showConfirmation(null)}
                  handleDiscard={onCancel}
                  handleDraft={handleDraft}
                  handlePublish={handlePublish}
                  dirty={dirty}
                  showDiscard={dirty}
                />
              )}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CreateEditForm;

const Step1 = ({ doc,supportsAltWo,altWo,handleAltWo, ...rest }) => {
  const { cid, isNew, errors, loading, handleChange, woSubTypes } = rest;
  const {
    is_single_video,
    ref_name,
    title,
    alt_id,
    desc, intro,
    intensity,
    thumbnail,
    sub_type = "regular",
    target_hr = [],
    target_cals,
  } = doc;
  const spcl = SPCL.includes(cid);
  return (
    <>
      {/*--- Structure or Follow Along wo ---*/}
      <RadioGroup
        name="is_single_video"
        value={is_single_video}
        onChange={handleChange}
        classes={{ root: "flex-row mb-20" }}
        defaultValue={0}
        
      >
        <FormControlLabel
          value={0}
          control={
            <Radio
              className="p-0 mr-10"
              color="primary"
              checked={!is_single_video}
              value={0}
              disabled={!isNew}
            />
          }
          label={`Structured${spcl ? '' : ' Workout'}`}
          classes={{
            root: "fm-0 fmr-30",
            label: clsx("font_16_500", !is_single_video && "font-weight-600"),
          }}
        />
        <FormControlLabel
          value={1}
          control={
            <Radio
              className="p-0 mr-10"
              color="primary"
              checked={!!is_single_video && (!spcl || !intro)}
              value={1}
              disabled={!isNew && !is_single_video}
            />
          }
          label={`Follow Along${spcl ? '' : ' Video Workout'}`}
          classes={{
            root: "fm-0 fml-10 fmr-30",
            label: clsx("font_16_500", !!is_single_video && "font-weight-600"),
          }}
        />
        {spcl && <FormControlLabel
          value={2}
          control={
            <Radio
              className="p-0 mr-10"
              color="primary"
              checked={!!is_single_video && !!intro}
              value={2}
              disabled={!isNew && !is_single_video}
            />
          }
          label="Introduction"
          classes={{
            root: "fm-0 fml-10",
            label: clsx("font_16_500", !!is_single_video && "font-weight-600"),
          }}
        />}
      </RadioGroup>
      {/*--- Structure or Follow Along wo -end ---*/}
      {/*--- Alert ---*/}
      {isNew && <Alert severity="info" className="mb-20">
        {!is_single_video
          ? "You will be able to add Exercises to this workout in the next step"
          : `Add ${intro ? 'intro' : 'follow along'} video to this workout in the next step`}
      </Alert>}
      {/*--- Alert -end ---*/}
      <div className="d-flex fmb-20">
        <div  className={clsx("flex-1 d-flex flex-column justify-content-between",!doc.is_single_video && "mr-20" )}>
          <FormTextField fullWidth label="Reference Name" required>
            <TextField
              id="ref_name"
              autoComplete="off"
              defaultValue={ref_name}
              onChange={handleChange}
              error={!!errors.ref_name}
              helperText={errors.ref_name}
              variant="outlined"
              disabled={loading}
              required
              type="text"
              InputProps={{
                classes: {
                  root: "medium",
                  input: "size_16_500 medium",
                },
              }}
            />
          </FormTextField>
          <FormTextField
            fullWidth
            label="Display Name"
            required
            classes={{ control: "mb-0" }}
          >
            <TextField
              InputProps={{
                classes: {
                  root: "medium",
                  input: "size_16_500 medium",
                },
              }}
              id="title"
              onChange={handleChange}
              defaultValue={title}
              error={!!errors.title}
              helperText={errors.title}
              variant="outlined"
              disabled={loading}
              required
            />
          </FormTextField>
        </div>
        {!doc.is_single_video && <ImageFileUpload
          id="thumbnail" purpose="wo"
          thumbnail={thumbnail}
          onChange={handleChange}
          size="small"
        />}
      </div>
      {/* Type on UI */}
      <div className={`mb-20 ${intro ? 'd-none' : 'd-flex'}`}>
        <FormTextField fullWidth label="Type" classes={{ control: "fmr-20" }}>
          <Select
            fullWidth
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: "medium",
                  input: "size_16_500 select-medium",
                }}
                name="sub_type"
              />
            }
            value={sub_type}
            onChange={handleChange}
            disabled={loading}
            required
          >
            {woSubTypes.map((type) => (
              <MenuItem value={type.value}>{type.label}</MenuItem>
            ))}
          </Select>
        </FormTextField>
        <FormTextField
          fullWidth
          label="Intensity"
          classes={{ control: "fmb-0" }}
        >
          <Select
            displayEmpty
            fullWidth
            IconComponent={DropDownIcon}
            input={
              <OutlinedInput
                classes={{
                  root: "medium",
                  input: "size_16_500 select-medium",
                }}
                name="intensity"
              />
            }
            value={intensity||''}
            onChange={handleChange}
            disabled={loading}
            required
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="low">Low</MenuItem>
            <MenuItem value="moderate">Moderate</MenuItem>
            <MenuItem value="high">High</MenuItem>
          </Select>
        </FormTextField>
        {supportsAltWo && <AltWo altWo={altWo} alt_id={alt_id} handleChange={handleAltWo} />}
      </div>
      <div className={`mb-20 ${intro ? 'd-none' : 'd-flex'}`}>
        <FormTextField label="Target Calories" classes={{ control: "fmb-0 fpr-10 w-50" }}>
          <TextField
            name="target_cals"
            placeholder={"0"}
            onKeyDown={(e) => protectNumberInputs(e, { allowDecimal: true })}
            value={Number.isFinite(target_cals) ? roundMacros(target_cals, 1) : ""}
            onChange={handleChange}
            className='w-100'
            error={!!errors.target_cals && !!errors.target_cals}
            helperText={!!errors.target_cals && !!errors.target_cals}
            variant="outlined"
            disabled={loading}
            required
            type="number"
            InputProps={{
              endAdornment: <Typography className="text-grey">cals</Typography>,
              inputProps: {
                min: 0
              },
              classes: {
                root: "medium w-100",
                input: "size_16_500 select-medium"
              }
            }}
          />
        </FormTextField>
        <MinMaxField
          name="target_hr"
          value={target_hr}
          label="Target Heart Rate"
          labelClasses={{ control : 'mb-0 fpl-10 w-50' }}
          errors={errors}
          onChange={handleChange}
        />
      </div>
      <FormTextField
        fullWidth
        label="Description"
        classes={{ control: "mb-0" }}
      >
        <TextField
          multiline
          rows="2"
          rowsMax="10"
          id="desc"
          fullWidth
          onChange={handleChange}
          defaultValue={desc}
          error={!!errors.desc}
          helperText={errors.desc}
          variant="outlined"
          disabled={loading}
          required
          InputProps={{
            classes: {
              root: "large",
              input: "size_16_500",
            },
          }}
        />
      </FormTextField>
    </>
  );
};
