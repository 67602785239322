import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import update from "immutability-helper";
import _,{get} from "lodash";
import firebase from "fitbud/firebase";
import * as Sentry from '@sentry/browser';
import {DEFAULT_ERROR, YOUTUBE_VIDEO_URL_REGEX, VIMEO_VIDEO_URL_REGEX, FILE_STATUS, HUBSPOT_PROPS,PUBLISHED,DRAFT,DRAFT_STATES,DEFAULT_VID_UPLOAD_ERROR} from "fitbud/utils/constants";
import { uploadFile, getTags } from "fitbud/utils/services";
import exRdxFns from "fitbud/redux/exercises";
import fileUploadRdxFns from "fitbud/redux/fileUpload";
import appRdxFns from "fitbud/redux/app";
import exRepo from "fitbud/repo/exercises";
import ExerciseForm, { TUT_VIDEO } from "./exerciseForm";
import ExerciseDetail from "./exercisesDetail";
import {
  ERROR_TITLE,
  ERROR_REF_NAME,
  EXERCISE_STORAGE_FILE_PATH,
  ERROR_YOUTUBE_URL,
  ERROR_VIMEO_URL,
  OFFLINE_ERROR,
  FETCH_ERROR
} from "fitbud/utils/constants";
import { refreshDoc,isOnline } from "fitbud/utils/helpers";
import CircularLoader from "fitbud/components/CircularLoader";
import PageNotFound from "fitbud/views/pageNotFound";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { bffUpdateHubspotProp, createVidObject } from "fitbud/api";
import ExerciseStep from "./exerciseStep";
import {exportTags, mediaObjExist, sanitizeBodyParts} from "fitbud/views/workouts/helperfn";
import {calculatePublishStatus,checkErrInMediaUpload} from "fitbud/utils/catalog";
import {MAIN_VIDEO} from "./exerciseForm";
import {videoExists} from "fitbud/views/workouts/helperfn";
import { getExercise } from "fitbud/api";

const bps2taz = require("./bps2taz");

const _default = {
  title: "",
  ref_name: "",
  side: "",
  type: "duration",
  description: "",
  media_url:"",
  muscle_groups: [],
  media: [],
  thumbnail: "",
  steps: [],
  equipments: [],
  body_parts: [],
  is_mute_video: false,
  is_crop_square: false,
  source:"custom"
};

class ExerciseInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      isValidId: true,
      doc: props.doc,
      oldDoc: props.doc,
      draftDoc:{},
      publishedDoc:{},
      errors: {},
      editMode: false,
      equipmentsData: [],
      muscleGroups: [],
      sidesData: [],
      typesData:[],
      targetAreasData: [],
      dirty: false,
      uploadingError: {media_bgon:false,media_tutorial:false},
      showingDoc: PUBLISHED,
      viewOrigDoc: false,
      isStepEditorOpen:false,
      fetchedAlts:{}
    };
  }
  static contextType = FirebaseAuthContext;

  get docId() {
    return this.props.id;
  }

  get isNew() {
    return this.props.id === "new";
  }
  get draftFeature(){
    const {comp} = this.context;
    if(_.isUndefined(this.props.draftFeature)){
      return !!comp.data().features.draft;
    }
    return this.props.draftFeature;
  }
  get isDraftAvailable() {
    return !!_.get(this.state, "publishedDoc.publish_status",PUBLISHED).includes('draft');
  }
  handleChange = e => {
    let chng;
    if (e.target.type === "number") {
      e.target.value = parseInt(e.target.value);
    }

    chng = { [e.target.id || e.target.name]: e.target.value };
    //if editing fitbudd import exercises::
    if(this.state.doc.source === "fitbudd"){
      chng.source = "fitbudd_custom"
    }
    this.setState(s => ({ doc: { ...s.doc, ...chng } }));
  };
  handleTargetArea = values => {
    const newValues = values.map(val => val.value);
    const chng = { target_area: newValues };
    this.setState({ doc: { ...this.state.doc, ...chng } });
  };


  editMetaForm = () => {
    this.setState({ editMode: true });
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  };

  closeMetaForm = () => {
    const newState = update(this.state, {
      doc: {
        $set: { ...this.state.oldDoc }
      },
      editMode: {
        $set: false
      },
      dirty: {
        $set: false
      },
      errors: { 
        $set: {} 
      },
    });
    this.setState(newState);
  };

  handleChips = (name, target) => {
    const newValues = target.map(val => val.value);
    const chng = { [name]: newValues };
    this.setState({ doc: { ...this.state.doc, ...chng }, dirty: true });
  };
  videoStatusRefresh = (type) => {
    const { files } = this.props;
    const {showingDoc,draftDoc,publishedDoc} = this.state;
    const ongoingPipeline=draftDoc && !!draftDoc.to_publish && publishedDoc && publishedDoc.publish_status.includes('draft');
    let _currentshowingDoc=ongoingPipeline?PUBLISHED:showingDoc;
    const media=_.get(
      _currentshowingDoc === PUBLISHED ? { ...publishedDoc } : { ...draftDoc },
      type === "media_bgon" ? "media.0" : "media_tutorial",
      {}
    );
    const file = files.find(i=>i.id===media.videoId)||{};
    if (file.status === FILE_STATUS.processing ||media.status===FILE_STATUS.processing) {
      const fetchEx =()=> !this.isNew && this.fetchEx(_currentshowingDoc===DRAFT);
      refreshDoc(fetchEx, this.props, (data) => {
        console.log('-----------------',data);
        const latestPublishedStatus=_.get(data,'doc.publish_status');
        if(ongoingPipeline){
          if(latestPublishedStatus===DRAFT_STATES['PUBLISHED']){
          //pipeline completed
          this.setState({
            publishedDoc:{...data.doc},
            doc: { ...data.doc },
            oldDoc:{...data.doc},
            showingDoc:PUBLISHED
          })
          }
          else{
            //ignore
          }
        }
        else{
          this.setState({
            [showingDoc===DRAFT?"draftDoc":"publishedDoc"]:{...data.doc},
            doc:{...data.doc},
            oldDoc:{...data.doc}
          })
        }
    });
    }
  }
  //--
  // handleMediaDuration=async(seconds)=>{
  //    let out = {
  //     total_duration:Math.round(seconds)
  //   };
  //   this.setState({ doc: { ...this.state.doc, ...out } });
  // }
  updateMediaMeta=(mediaType,args={})=>{
    const {media=[],media_tutorial={}}=this.state.doc;
    if(mediaType===MAIN_VIDEO){
      if(media && !!_.isMatch(media[0],args)) return;
      const newState=update(this.state,{
        doc:{
          media:{
            0:{
              $merge:{
                ...args
              }
            }
          }
        }
      });
      this.setState({
        ...newState
      });
    }
    else{
      if(!!_.isMatch(media_tutorial,args)) return;
      const newState=update(this.state,{
        doc:{
          media_tutorial:{
            $merge:{
              ...args
            }
          }
        }
      });
      this.setState({
        ...newState
      });
    }
  }
  handleMedia = async (media, mediaType) => {
    const {type, url} = media;
    let newState = this.state;
    if(type==='thumbnail'){
      newState = update(newState, {
        doc: {
          thumbnail: {
            $set: {
              file: url,
              url: URL.createObjectURL(url),
            }
          }
        }
      });
    }
    else{
      //video update
      if(mediaType===MAIN_VIDEO){
        //primary video
        newState = update(newState, {
          doc:{
            media:{
              $set: [media],
            }
          },
          dirty:{
            $set:true
          }
        });
      }
      else{
        //tutorial video
        newState = update(newState, {
          doc:{
            media_tutorial:{
              $set: { ...media },
            }
          },
          dirty:{
            $set:true
          }
        });
      }
    }
    if(newState.doc.source==="fitbudd"){
      newState=update(newState,{
        doc:{
          source:{
            $set:"fitbudd_custom"
          }
        }
      })
    }
    this.setState(newState);
  };

  handleVideoFeature = (e) => {
    const name=e.target.id || e.target.name;
    const value = name==='total_duration'?(e.target.value||0): e.target.checked;
    let out = {
      [name]:value
    };
    this.setState({ doc: { ...this.state.doc, ...out } });
  }

  removeMedia = mediaType => {
    let newState;
    if(mediaType===MAIN_VIDEO){
      newState=update(this.state,{
        doc:{
          media:{
            $set:[
              {type:"video", url:""}
            ]
          }
        },
        dirty:{
          $set:true
        },
      });
      this.setState(newState);
    }
    else if(mediaType===TUT_VIDEO){
      newState=update(this.state,{
        doc:{
          media_tutorial:{
            $set:{type:"video", url:""}
          }
        },
        dirty:{
          $set:true
        },
      });
      this.setState(newState);
    }
  };

  valid = (doc) => {
    let out = true;
    let errors = {};
    const { title="", ref_name="", type, media, media_tutorial } =doc;
    if (!title.trim()) {
      errors.title = ERROR_TITLE("display name");
      out = false;
    }
    if (!ref_name.trim()) {
      errors.ref_name = ERROR_REF_NAME;
      out = false;
    }
    if (media && media[0] && !videoExists(media[0])) {
      if(media[0].type === "youtube") {
        if(!YOUTUBE_VIDEO_URL_REGEX.test(media[0].url)) {
          errors.mediaUrl = ERROR_YOUTUBE_URL;
          out = false;
        }
      } else if(media[0].type === "vimeo") {
        if(!VIMEO_VIDEO_URL_REGEX.test(media[0].url)) {
          errors.mediaUrl = ERROR_VIMEO_URL;
          out = false;
        }
      } 
    }
    if(media_tutorial && videoExists(media_tutorial) && !videoExists(media[0])){
      errors.mediaUrl = 'Please upload exercise video along with tutorial video';
      errors.mediaUpload = 'Please upload exercise video along with tutorial video';
      out = false;
    }
    if(media_tutorial && !videoExists(media_tutorial)){
      if(media_tutorial.type === "youtube") {
        if(!YOUTUBE_VIDEO_URL_REGEX.test(media_tutorial.url)) {
          errors.tutMediaUrl = ERROR_YOUTUBE_URL;
          out = false;
        }
      } else if(media_tutorial.type === "vimeo") {
        if(!VIMEO_VIDEO_URL_REGEX.test(media_tutorial.url)) {
          errors.tutMediaUrl = ERROR_VIMEO_URL;
          out = false;
        }
      } 
    }
    if (!type) {
      errors.type = "Please provide a valid exercise type";
      out = false;
    }
    this.setState({ errors });
    return out;
  };


  deleteItem = () => {
    const { onDelete, enqueueSnackbar, hideLoader, showLoader } = this.props;
    showLoader();
    exRepo(this.context.cid,this.draftFeature)
      .delete(this.docId)
      .then((doc) => {
        hideLoader();
        if (doc) {
          this.props.delete(doc.id);
          if (!!onDelete) onDelete();
          enqueueSnackbar("Exercise deleted successfully.", {
            variant: "success"
          });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  }

  copyItem = () =>{
    const { doc } = this.state;
    const {
      insert,
      onSave,
      enqueueSnackbar,
      hideLoader,
      showLoader,
    } = this.props;
    showLoader();
    let copyDoc = update(doc,{
      ref_name:{
        $set:`Copy of ${doc.ref_name}`
      }
    })
    exRepo(this.context.cid,this.draftFeature)
      .create(copyDoc)
      .then((doc) => {
        const _rdxState = {
          cid: this.context.cid, 
          ref_name: doc.data().ref_name, 
          has_instructions: !!(doc.data().steps || []).length,
          source:doc.data().source,
          thumbnail:doc.data().thumbnail,
          category:doc.data().category,
          publish_status:doc.data().publish_status
       }
        hideLoader();
        if (doc) {
          insert({
            _id: doc.id,
            data: _rdxState,
          });
          if (!!onSave) onSave(doc);
          enqueueSnackbar("Exercise copy successfully.", { variant: "success" });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  }
  uploadVideoToBunnyCDN=async({id,file,isPublish,docName,mediaParentKey,fieldPath,...videoObject})=>{
    const { uploadBunnyFile } = this.props;
    uploadBunnyFile({
      docId:id,
      cid:this.context.cid,
      file:file.url,
      collection:isPublish ? "exercises" : "draft-exercises",
      media_bgoff:false,
      path:`/fitness/exercises/${id}`,
      docName,
      videoObject,
      mediaParentKey,
      fieldPath
    });
  }
  handlePublish=async(params)=>{
    //to test if params is 'e' , using param.target
     const isE=!!params.target;
    if(this.isDraftAvailable && this.state.showingDoc!==DRAFT){
      await this.toggleDoc(DRAFT);
    }
    this.onSubmit(PUBLISHED,isE?{}:params);
  }
  handleDraft =(params={}) => {
    //to test if params is 'e' , using param.target
    const isE=!!params.target;
    this.onSubmit(DRAFT,isE?{}:params);
  };
  
  createVideoObject=async({id,isPublish,duration,title,resolution,mediaParentKey,fieldPath})=>{
    try{
      const collection=isPublish ? "exercises" : "draft-exercises";
      const resp=await createVidObject({
        cid:this.context.cid,
        docId:id,
        path: `companies/${this.context.cid}/${collection}/${id}`,
        collection,
        media_bgoff:false,
        video:{
          title,
          duration,
          resolution
        },
        mediaParentKey,
        fieldPath
      });
      return await resp.data;
    }
    catch(err){
      this.props.enqueueSnackbar(_.get(err,"response.data.message",DEFAULT_VID_UPLOAD_ERROR),{variant:"error"});
      console.log("err",err);
      return null;
    }
  }
  sanitizeCodeBeforeSave = (orignalDoc,primMediaVideObj,secMediaVidObj) => {
    let updatedDoc=_.cloneDeep(orignalDoc);
    const { media = {}, media_tutorial = {} } = updatedDoc;
    updatedDoc["description"] = updatedDoc["description"].trim();
    if(updatedDoc.media_tutorial && !Object.keys(_.get(updatedDoc,'media_tutorial',{})).length || !mediaObjExist(media_tutorial)){
      updatedDoc.media_tutorial=null;
    }
    const newMediaAvailable=!!get(media,"0.url.name", null);
    const newSecMediaAvailable=!!get(media_tutorial,"url.name", null);
      //-----primary video----
    if (!!newMediaAvailable) {
      updatedDoc = update(updatedDoc, {
        media: {
          $set: [{ 
            type: "video", 
            status: primMediaVideObj?FILE_STATUS.uploading:FILE_STATUS.error, 
            videoId:_.get(primMediaVideObj,"videoId",""),
          }],
        },
      });
    }
    else{
      if(!!get(media,"0",null) && ["youtube", "vimeo"].includes(media[0].type) && media[0].url){
        updatedDoc=update(updatedDoc,{
          media:{
            $set:[{
              type: media[0].type, url: media[0].url, ...(!!media[0].error && {error:media[0].error})
            }]
          }
        });
      }
    }
    if (!!newSecMediaAvailable) {
      updatedDoc = update(updatedDoc, {
        media_tutorial: {
          $set: { 
            type: "video", 
            status: secMediaVidObj?FILE_STATUS.uploading:FILE_STATUS.error, 
            videoId:_.get(secMediaVidObj,"videoId",""),
          },
        },
      });
    }
    else{
      if(!!media_tutorial && ["youtube", "vimeo"].includes(media_tutorial.type) && media_tutorial.url){
        updatedDoc=update(updatedDoc,{
          media_tutorial:{
            $set:{
              type: media_tutorial.type, url: media_tutorial.url, ...(!!media_tutorial.error && {error: media_tutorial.error})
            }
          }
        })
      }
    }
    return { ...updatedDoc };
  }
  //--
  onSubmit = async (docType=PUBLISHED,params={}) => {
    const {
      insert,
      onSave,
      enqueueSnackbar,
      hideLoader,
      showLoader,
      closeSearch,
    } = this.props;
    const { isDbUsed, ...rest } = this.state.doc;
    let orignalDoc=Object.assign({}, rest,params);
    const { media,media_tutorial } = orignalDoc;
    const calculatedDocType=calculatePublishStatus(this.isNew,this.draftFeature,this.state.oldDoc,docType);
    const isPublish=calculatedDocType===DRAFT_STATES['PUBLISHED']
    if (!this.valid(orignalDoc)) return;
    showLoader();   
    let newMediaAvailable=!!_.get(media,"0.url.name", null);
    let newSecMediaAvailable=!!_.get(media_tutorial,"url.name", null);
    let firebaseId;
    if(newMediaAvailable){
      firebaseId=this.isNew?firebase
      .firestore()
      .collection(`companies/${this.context.cid}/exercises`)
      .doc().id:this.docId;
      //create video obj
      const resp=await this.createVideoObject({
        id:firebaseId,
        isPublish,
        duration:_.get(media,"0.duration"),
        title:_.get(media,"0.url.name",""),
        resolution:_.get(media,"0.height","")
      });
      if(!resp){
        hideLoader();
        return;
      }
      newMediaAvailable=resp;
      if(this.isNew){
        orignalDoc=update(orignalDoc,{
          docIdx:{
            $set:firebaseId
          }
        });
      }
    }
    if(newSecMediaAvailable){
      firebaseId=firebaseId||(
        this.isNew?firebase.firestore().collection(`companies/${this.context.cid}/exercises`)
        .doc().id:this.docId
      );
      //create video obj
      const resp=await this.createVideoObject({
        id:firebaseId,
        isPublish,
        duration:_.get(media_tutorial,"duration"),
        title:_.get(media_tutorial,"url.name",""),
        resolution:_.get(media_tutorial,"height",""),
        mediaParentKey:'media_tutorial',
        fieldPath:'media_tutorial'
      });
      if(!resp){
        hideLoader();
        return;
      }
      newSecMediaAvailable=resp;
      if(this.isNew && !orignalDoc.docIdx){
        orignalDoc=update(orignalDoc,{
          docIdx:{
            $set:firebaseId
          }
        });
      }
    }

    let updatedDoc = { ...this.sanitizeCodeBeforeSave(orignalDoc,newMediaAvailable,newSecMediaAvailable) };
    const { thumbnail } = updatedDoc;
    if (thumbnail && thumbnail.file) {
      const imageUrl = await uploadFile({
        file: thumbnail.file,
        filePath: EXERCISE_STORAGE_FILE_PATH
      });
      updatedDoc = update(updatedDoc, {
        thumbnail: {
          $set: imageUrl.replace("original", "240"),
        },
      });
    }
    const _rdxState = {
       cid: this.context.cid, 
       ref_name: updatedDoc.ref_name, 
       has_instructions: !!(updatedDoc.steps || []).length,
       source:updatedDoc.source,
       thumbnail: (calculatedDocType===DRAFT_STATES['DRAFT_ONLY']||calculatedDocType===DRAFT_STATES["PUBLISHED"])?updatedDoc.thumbnail:_.get(this.state.publishedDoc,'thumbnail'),
       category:updatedDoc.category,
       publish_status:calculatedDocType
    }
    if (this.isNew) {
      const createNewId=(newMediaAvailable||newSecMediaAvailable)?false:true;
      exRepo(this.context.cid,this.draftFeature)
        .create(updatedDoc,null,createNewId)
        .then(async (doc) => {
          delete updatedDoc.docIdx;
          hideLoader();
          bffUpdateHubspotProp(
            isDbUsed
              ? HUBSPOT_PROPS.EXERCISE_DB_USED
              : HUBSPOT_PROPS.EXERCISE_CREATED
          );
          if (doc) {
            if (newMediaAvailable) {
              //upload video in bunnycdn
              this.uploadVideoToBunnyCDN({
                id:doc.id,
                file: media[0],
                isPublish,
                docName: doc.data().ref_name,
                ...newMediaAvailable,
              });
            }
            if(newSecMediaAvailable){
              this.uploadVideoToBunnyCDN({
                id:doc.id,
                file:media_tutorial,
                isPublish,
                docName: doc.data().ref_name,
                mediaParentKey:'media_tutorial',
                fieldPath:'media_tutorial',
                ...newSecMediaAvailable
              });
            }
            insert({
              _id: doc.id,
              data: _rdxState
            });
            if (!!onSave) onSave(doc);
            closeSearch &&  closeSearch(); // close Search dialog
          }
        })
        .catch((err) => {
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    } else {
      //----hack-----
      //avoids BE flag pipeline, directly puts media in main collection, check flow in companyBase
      if (
        isPublish &&
        newMediaAvailable &&
        (updatedDoc.publish_status || "").includes("draft")
      ) {
        updatedDoc["to_publish"] = true;
      }
      //-------------
      exRepo(this.context.cid, this.draftFeature)
        .update(this.docId, updatedDoc, null, docType)
        .then((doc) => {
          hideLoader();
           if (doc) {
            if (newMediaAvailable) {
                // upload video in bunnycdn
                this.uploadVideoToBunnyCDN({
                  id:doc.id,
                  file: media[0],
                  isPublish,
                  docName: updatedDoc.ref_name,
                  ...newMediaAvailable,
                });
            }
            if(newSecMediaAvailable){
              this.uploadVideoToBunnyCDN({
                id:doc.id,
                file:media_tutorial,
                isPublish,
                docName: updatedDoc.ref_name,
                mediaParentKey:'media_tutorial',
                fieldPath:'media_tutorial',
                ...newSecMediaAvailable
              });
            }
            this.props.update({
              _id: doc.id,
              data: _rdxState
            });
            if (!!onSave) onSave(doc);
            const to_publish = doc.data().to_publish;
            //---TODO ⬇︎ :replace docType with calculatedDocType ?? 
            this.setState((o) => ({
              editMode: false,
              showingDoc: to_publish ? DRAFT : docType,
              publishedDoc:(docType===PUBLISHED && !to_publish)?doc.data():{...(o.publishedDoc||{}),publish_status:doc.data().publish_status},
              draftDoc:docType===DRAFT?doc.data():{...(o.draftDoc||{}),to_publish},
              oldDoc: doc.data(),
              doc: doc.data(),
              dirty: false,
            }));
            //-------
          }
        })
        .catch((err) => {
          console.log(">>err", err);
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    }
  };

  setDirtyOnChange = () => {
    this.setState({dirty: true});
  }


  handleCancelWrapper = e => {
    this.setState({dirty: false});
    this.props.onCancel(e);
  }
  toggleDoc=(docType)=>{
    const { draftDoc, publishedDoc,targetAreasData } = this.state;
    const doc = this.parseCurrentDoc(docType === DRAFT ? { ...draftDoc } : { ...publishedDoc },targetAreasData);
    if (!Object.keys(doc).length) return;
    this.setState({ showingDoc: docType,doc:{...doc},oldDoc:{...doc}});
  }
  discardDraft=()=>{
    const { showLoader, hideLoader, enqueueSnackbar, onDelete } = this.props;
    showLoader();
    exRepo(this.context.cid,this.draftFeature)
    .deleteDraft(this.docId,null,this.state.doc.publish_status)
    .then((doc)=>{
      hideLoader();
      if (!doc) return;
      const updatedDoc = doc.data();
      if (_.get(updatedDoc, "publish_status", "") === DRAFT_STATES["DRAFT_ONLY"]) {
        this.props.delete(doc.id);
        if (!!onDelete) onDelete();
      }
      else if (_.get(updatedDoc, "publish_status", "") === DRAFT_STATES["PUBLISHED"]) {
        this.setState(o=>({
          oldDoc: { ...updatedDoc },
          doc: { ...updatedDoc },
          publishedDoc:{...o.publishedDoc,publish_status:PUBLISHED},
          showingDoc:PUBLISHED
        }));
        this.props.update({
          _id:doc.id,
          data:{
            cid: this.context.cid,
            ref_name: updatedDoc.ref_name,
            has_instructions: !!(updatedDoc.steps || []).length,
            source:updatedDoc.source,
            thumbnail:updatedDoc.thumbnail,  
            category:updatedDoc.category,
            publish_status:updatedDoc.publish_status,
          }
        })
      }
      enqueueSnackbar("Draft deleted successfully.", { variant: "success" });
    })
    .catch((err) => {
      hideLoader();
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    });
  }
  openEditor=()=>{
    this.setState({isStepEditorOpen:true});
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  }
  closeEditor=()=>{
    const newState = update(this.state, {
      doc: {
        $set: { ...this.state.oldDoc }
      },
      isStepEditorOpen: {
        $set: false
      },
      dirty: {
        $set: false
      },
      errors: { 
        $set: {} 
      },
    });
    this.setState(newState);
  }
  stopPublishPipeline=async()=>{
    try{
      const draftDoc=await exRepo(this.context.cid,this.draftFeature)
      .stopPublishPipeline(this.docId);
      if(!draftDoc || !draftDoc.exists) {
        this.props.enqueueSnackbar(DEFAULT_ERROR,{variant:"error"});
        return;
      }
      const parsedDraftDoc=this.parseCurrentDoc(draftDoc.data(),this.state.targetAreasData);
      this.setState(o=>({
          draftDoc:{...parsedDraftDoc},
          doc:o.showingDoc===DRAFT?{...parsedDraftDoc}:{...o.doc},
          oldDoc:o.showingDoc===DRAFT?{...parsedDraftDoc}:{...o.oldDoc},
        }));
      return draftDoc;
    }
    catch(err){
      this.props.enqueueSnackbar(DEFAULT_ERROR,{variant:"error"});
      console.log(err);
    }
  }
  handleAddAlts=async(val=[])=>{
    const {showLoader,hideLoader,enqueueSnackbar}=this.props;
    showLoader();
    const updatedAltIds=_.uniq(val.map(i=>i._id));
    try{
      await exRepo(this.context.cid)
        .update(this.docId,{
          alts:[...updatedAltIds]
        });
      //TODO:update published/draft doc 
      const newState=update(this.state,{
        fetchedAlts:{
          $set:[
            ...val
          ]
        },
        doc:{
          alts:{
            $set:[...updatedAltIds]
          }
        },
        oldDoc:{
          alts:{
            $set:[...updatedAltIds]
          }
        }
      });
      this.setState({...newState});
      hideLoader();
    }
    catch(err){
      hideLoader();
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    }
  }
 
  render() {
    const {
      doc,
      oldDoc,
      errors,
      editMode,
      sidesData,
      muscleGroups,
      equipmentsData,
      targetAreasData,
      typesData,
      loader,
      isValidId,
      showingDoc,
      isStepEditorOpen,
      draftDoc,
      fetchedAlts,
      uploadingError,
    } = this.state;
    console.log('doc',doc)
    const { loading, onSave } = this.props;
    if (!isValidId) return <PageNotFound keyName="exercise" />;
    if (this.isNew)
      return (
        <ExerciseForm
          loading={loading}
          isNew
          doc={doc}
          errors={errors}
          onSave={onSave}
          handleChange={this.handleChange}
          onCancel={this.handleCancelWrapper}
          onSubmit={this.onSubmit}
          handleMedia={this.handleMedia}
          removeMedia={this.removeMedia}
          sidesData={sidesData}
          muscleGroups={muscleGroups}
          equipmentsData={equipmentsData}
          handleChips={this.handleChips}
          targetAreasData={targetAreasData}
          dirty={this.state.dirty}
          setDirtyOnChange={this.setDirtyOnChange}
          handleVideoFeature={this.handleVideoFeature}
          typesData={typesData}
          updateMediaMeta={this.updateMediaMeta}
          cid={this.context.cid}
        />
      );
    return (
      <div className="overflow-x-hidden h-100 position-relative">
        {loader && <CircularLoader className="position-absolute" />}
        {doc && (
          <ExerciseDetail
            id={this.props.id}
            doc={{ ...oldDoc}}
            handleEditMode={this.editMetaForm}
            sidesData={sidesData}
            muscleGroups={muscleGroups}
            equipmentsData={equipmentsData}
            targetAreasData={targetAreasData}
            typesData={typesData}
            cid={this.context.cid}
            copyItem={this.copyItem}
            deleteItem={this.deleteItem}
            uploadingError={uploadingError}
            videoStatusRefresh={this.videoStatusRefresh}
            editMode={editMode}
            isPreview={this.props.isPreview}
            openStepEditor={this.openEditor}
            draftBannerProps={{
              toggleDoc:this.toggleDoc,
              isDraftAvailable:this.isDraftAvailable,
              handlePublish:this.handlePublish,
              discardDraft:this.discardDraft,
              showingDoc:showingDoc,
              videoStatusRefresh:this.videoStatusRefresh,
              draftDoc
            }}
            draftDoc={draftDoc}
            isDraftAvailable={this.isDraftAvailable}
            stopPublishPipeline={this.stopPublishPipeline}
            handleAddAlts={this.handleAddAlts}
            fetchedAlts={fetchedAlts}
          />
        )}
        {editMode && (
          <ExerciseForm
            loading={loading}
            doc={doc}
            errors={errors}
            handleChange={this.handleChange}
            handleChips={this.handleChips}
            onCancel={this.closeMetaForm}
            onSubmit={this.onSubmit}
            handleMedia={this.handleMedia}
            removeMedia={this.removeMedia}
            sidesData={sidesData}
            muscleGroups={muscleGroups}
            equipmentsData={equipmentsData}
            targetAreasData={targetAreasData}
            dirty={this.state.dirty}
            setDirtyOnChange={this.setDirtyOnChange}
            handleVideoFeature={this.handleVideoFeature}
            typesData={typesData}
            handleDraft={this.handleDraft}
            handlePublish={this.handlePublish}
            videoStatusRefresh={this.videoStatusRefresh}
            videoUploadError={uploadingError}
            draftFeature={this.draftFeature}
            isDraftAvailable={this.isDraftAvailable}
            updateMediaMeta={this.updateMediaMeta}
            cid={this.context.cid}
          />
        )}
        {isStepEditorOpen && (
          <ExerciseStep
           docId={this.docId}
           cid={this.context.cid}
           steps={doc.steps}
           onClose={this.closeEditor}
           handleDraft={this.handleDraft}
           handlePublish={this.handlePublish}
           draftFeature={this.draftFeature}
           isDraftAvailable={this.isDraftAvailable}
          />
        )}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState){
    //-----------------------media upload check------------------------
    const files=_.get(this.props, "files", []);
    const primaryMediaObj= _.get(this.state, "oldDoc.media.0", {});
    const secondaryMediaObj= _.get(this.state, "oldDoc.media_tutorial", {})||{};
    const primMediaError=checkErrInMediaUpload(primaryMediaObj,files);
    const secMediaError=checkErrInMediaUpload(secondaryMediaObj,files);
    const error={
      media_bgon:!!primMediaError,media_bgoff:!!secMediaError
    }
    if(!_.isEqual(this.state.uploadingError,error)){
      this.setState({ uploadingError: error });
    }
    //-------------------Update localDoc on file upload--------
    if(primaryMediaObj.status===FILE_STATUS.uploading){
      const isPrimFileInRedux=files.find(i=>i.id===primaryMediaObj.videoId);
      if(isPrimFileInRedux && isPrimFileInRedux.status===FILE_STATUS.processing){
        const oldDoc=update(this.state.oldDoc,{
          media:{
            0:{
              status:{
                $set:FILE_STATUS.processing
              }
            }
          }
        });
        if(!this.draftFeature){
          //update publishedDoc only
          const publishedDoc=update(this.state.publishedDoc,{
            media:{
              0:{
                status:{
                  $set:FILE_STATUS.processing
                }
              }
            }
          });
          this.setState({publishedDoc});
        }
        else{
          //update draftDoc only
          const draftDoc=update(this.state.draftDoc,{
            media:{
              0:{
                status:{
                  $set:FILE_STATUS.processing
                }
              }
            }
          });
          this.setState({draftDoc});
        }
        this.setState({
          doc:{...oldDoc},
          oldDoc:{...oldDoc}
        });
      }
    }
    if(secondaryMediaObj.status===FILE_STATUS.uploading){
      const isSecFileInRedux=files.find(i=>i.id===secondaryMediaObj.videoId);
      if(isSecFileInRedux && isSecFileInRedux.status===FILE_STATUS.processing){
        const oldDoc=update(this.state.oldDoc,{
          media_tutorial:{
            status:{
              $set:FILE_STATUS.processing
            }
          }
        });
        if(!this.draftFeature){
          //update publishedDoc only
          const publishedDoc=update(this.state.publishedDoc,{
            media_tutorial:{
              status:{
                $set:FILE_STATUS.processing
              }
            }
          });
          this.setState({publishedDoc});
        }
        else{
          //update draftDoc only
          const draftDoc=update(this.state.draftDoc,{
            media_tutorial:{
              status:{
                $set:FILE_STATUS.processing
              }
            }
          });
          this.setState({draftDoc});
        }
        this.setState({
          doc:{...oldDoc},
          oldDoc:{...oldDoc}
        });
      }
    }
  }
  parseCurrentDoc=(doc,targetAreasData)=>{
    if(!doc.source){
      doc.source = !!doc.id ? "fitbudd" :"custom"
    }
    if(!!doc.side && doc.side==="none"){
      doc.side="";
    }
    if(!!doc.category && doc.category==="none"){
      doc.category="";
    }
    doc.body_parts=sanitizeBodyParts(doc.body_parts||[],targetAreasData,bps2taz);
    return {...doc};
  }
  getExDoc=async(targetAreasData)=>{
    if(this.isNew && !this.props.exerciseData) return;
    this.setState({ loader: true });
    let doc,
    oldDoc=_.cloneDeep({...this.state.oldDoc}),
    publishedDoc = _.cloneDeep({ ...this.state.publishedDoc }),
    draftDoc = _.cloneDeep({ ...this.state.draftDoc }),
    showingDoc;
    //TODO
    // this.props.edcData;
    if(this.isNew && this.props.exerciseData){
      doc={...this.props.exerciseData};
      oldDoc={...this.props.exerciseData};
    }
    else if(!this.isNew){
      //get published doc
      const exerciseDoc = await this.fetchEx();
      if (!(exerciseDoc.exists)) {
        this.setState({
          isValidId: false,
          loader: false,
        });
        return;
      }
      publishedDoc = exerciseDoc.data ? exerciseDoc.data() : exerciseDoc;
      //if draft feature is off, treat main collection doc as published
      if(!this.draftFeature){
        publishedDoc['publish_status']=PUBLISHED;
      }
      const publish_status = _.get(publishedDoc, "publish_status", PUBLISHED);
      if (publish_status.includes("draft")) {
        //get draft doc
        const draftWo = await this.fetchEx(true);
        draftDoc = !!draftWo.data ? draftWo.data() : draftWo;
      }
      if (publish_status === DRAFT_STATES["DRAFT_ONLY"]) {
        doc = { ...draftDoc };
        showingDoc = DRAFT;
        oldDoc = { ...draftDoc };
      } else {
        doc = { ...publishedDoc };
        showingDoc = PUBLISHED;
        oldDoc = { ...publishedDoc };
      }    
    }
    const fetchedAlts=await this.fetchAlts(doc.alts||[]);
    doc={...this.parseCurrentDoc(doc,targetAreasData)};
    oldDoc={...this.parseCurrentDoc(oldDoc,targetAreasData)}
    this.setState({
      doc,
      oldDoc,
      publishedDoc,
      draftDoc,
      loader: false,
      showingDoc,
      fetchedAlts
    });
  }
  componentDidMount() {
    this.setState({ loader: true });
    const fetchEquipments = () => getTags("equipments");
    const fetchMuscleGroups = () => getTags("muscleGroups");
    const fetchSides = () => getTags("sides");
    const fetchTargetAreas = () => getTags("targetAreas");
    const fetchTypes = () => getTags("exCats");
    Promise.all([
      fetchEquipments(),
      fetchMuscleGroups(),
      fetchSides(),
      fetchTargetAreas(),
      fetchTypes()
    ]).then(([ equipments, muscleGroups, sides, targetAreas,types]) => {
        let equipmentsData=exportTags(equipments),
            muscleGroupsData=exportTags(muscleGroups),
            sidesData=exportTags(sides),
            //TODO
            // sidesData = Object.entries(sides);
            targetAreasData=exportTags(targetAreas),
            typesData=exportTags(types);
        this.getExDoc(targetAreasData);
        this.setState({
          targetAreasData,
          sidesData,
          equipmentsData,
          muscleGroups: muscleGroupsData,
          typesData,
          // loader: false
        });
      })
      .catch(err => {
        const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
        this.setState({loader:false})
      });
  }

  fetchEx = (isDraft = false) => {
    // if (this.props.loading) return;
    return this.docId.match(/.*_ftbd_ex.*/) ? getExercise(this.docId.split('/')[3]).then(d => console.log(d) || {data: ()=> d.data, exists: true}) : exRepo(this.context.cid,this.draftFeature).doc(this.docId,null,isDraft);
  };
  fetchAlts=async( ids=[])=>{
    //only called at didmount
    const altDataSnapshot=await Promise.all(
      ids.map(i=>exRepo(this.context.cid).doc(i))
    );
    return altDataSnapshot.map(i=>({
      _id:i.id,
      data:{
        ...i.data(),
        id:i.id
      }
    }))
  }
}

const mapStateToProps = (s, op) => {
  const id = op.id;
  if (id === "new")
    return {
      doc: { ..._default },
      loading: s.exercises.loading,
      files : s.fileUpload.files
    };
  // const doc = _.find(s.exercises.docs, d => id === d._id);
  // if (!!doc)
  //   return {
  //     doc: doc.data,
  //     loading: s.exercises.loading,
  //     cid: s.app.cid
  //   };
  else return { doc: undefined, loading: s.exercises.loading,files : s.fileUpload.files  };
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const { uploadFile,uploadBunnyFile } = fileUploadRdxFns(d);
  return { ...exRdxFns(d), showLoader, hideLoader, uploadFile,uploadBunnyFile };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(ExerciseInfo)
);
