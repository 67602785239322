import React,{useContext,useMemo} from 'react';
import { DialogContent,  Divider, IconButton, Typography, makeStyles } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import { ClrdButton } from 'fitbud/components/form-fields';
import { UserSchContext } from "fitbud/views/schedulev2";
import Dialog from "fitbud/components/Dialog";
import _ from 'lodash';
import MasterPill from '../schedulev2/editor/master-pill';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { DeleteIcon } from 'fitbud/icons/delete';
import Confirmation from 'fitbud/components/confirmationDialog';
import { useToggle } from 'fitbud/hooks/form';

export const Title=({date,week,day})=>(<>{date} &nbsp;&nbsp;•&nbsp;&nbsp;W{week+1} D{day + 1}</>);
  
const FitnessOptions = ({onClose, open}) => {
  const [confirm, toggleConfrm] = useToggle();
  const { controller, opimport, dates, local } = useContext(UserSchContext);
  const {week, day,weekId}=opimport;
  const date=dates[opimport.day].format('ddd, D MMM');
  const isRestDay=useMemo(()=>{
    return controller.isRestDay()
  },[]);
  const {wb=[], wa=[]}=useMemo(()=>{
    return _.get(local,['weeks',weekId,'data','d'+(day+1)],{});
  }, [weekId, day])
  const isMaster=useMemo(()=> controller.isMasterApplied({weekId,week,day}),[weekId,week,day]);
  const onSelect = (e) => {
    controller.importClick(e)
  };
  const config=useMemo(()=>({
    schedule:{
      onClick:onSelect,
      "data-week":opimport.week,
      "data-weekid":opimport.weekId,
      "data-day":opimport.day,
      "data-purpose":"woSch"
    },
    workout:{
      onClick:onSelect,
      "data-week":opimport.week,
      "data-weekid":opimport.weekId,
      "data-day":opimport.day,
      "data-purpose":"wo"
    },
    ai: {
      onClick: onSelect,
      "data-week": opimport.week,
      "data-weekid": opimport.weekId,
      "data-day": opimport.day,
      "data-purpose": "woSch",
    }
  }),[opimport, isRestDay]);
  const woExist=(!!wa && wa.length) || !!wb && !!wb.length;
  return (
      <Dialog
       open
       onClose={onClose}
       title={<Title date={date} {...opimport} />}
       titleFont="h3"
       paperClass="width-600"
      >
      <DialogContent className='p-0 overflow-hidden'>
        {!isMaster? (<div className='px-20 pt-25 '>
          <Typography className='font_18_600 mb-10' data-purpose='schedule'>
              Schedule
          </Typography>
          <Typography className='font_15_500 mb-20 text-dark-grey'>
              Import a multi-week workout schedule
          </Typography>
          <ClrdButton color="primary" variant="text" {...config['schedule']} 
            classes={{root:"ml-n8",label:"font_15_600"}}>
              Import Schedule<ArrowIcon className='ml-3px'/>
          </ClrdButton>
        </div>) :(
          <div style={{background:!isMaster.overridden?"#E8F4FD":"#F0F3F5"}} className='px-20 py-25'>
            <Typography className='mb-10 font_18_600'>{!isMaster.overridden?"Currently Active":"Broken Link"}</Typography>
            <div className='bg-white p-15 rounded mb-20 d-flex align-items-center'>
              {!isMaster.overridden ? <LinkIcon className='mr-15'/>:
              <LinkOffIcon className='mr-15'/>}
              <Typography className='font_15_500'>Linked with&nbsp;  
              <span className='font-weight-600'>W{isMaster.w} D{isMaster.dow}</span>
              &nbsp;of <span className='font-weight-600'>{isMaster.name}</span>
              </Typography>
            </div>
            <MasterPill l2={date} closeParent={onClose} type="wo">
              {
                ({handleManage,unlink,unlinkingMsg,unlinkTitle})=>(
                  <div className='d-flex justify-content-between align-items-center'>
                    <ClrdButton color="main" className="f-medium" onClick={handleManage}>
                      {!isMaster.overridden? "Manage":"Restore"}
                    </ClrdButton>
                    <IconButton onClick={toggleConfrm}>
                      <DeleteIcon/>
                    </IconButton>
                    {confirm && <Confirmation open={true} handleChange={unlink}
                      handleCancel={toggleConfrm} title={unlinkTitle} msg={unlinkingMsg}
                      confirmOption="Yes, Unlink"
                    />}
                  </div>
                )
              }
            </MasterPill>
          </div>
        )} 
        <Divider className={clsx("mb-25 position-relative",!isMaster && "mt-20")} style={{left:-20,width:'calc(100% + 40px)'}}/>
        <div className='px-20 pb-25'>
          <Typography className='font_18_600 mb-10'>
              Workout
          </Typography>
          <Typography className='font_15_500 mb-20 text-dark-grey'>
            Add a workout for this day
          </Typography>
          <ClrdButton color="primary" variant="text" {...config['workout']}
            classes={{
              root:"ml-n8",label:"font_15_600"
            }}> Add {woExist ? "more" : "" } Workout <ArrowIcon className='ml-3px'/>
          </ClrdButton>
        </div>  
      </DialogContent>
    </Dialog>
  );
};
export default FitnessOptions;
