import React, { useContext,useMemo} from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import DividerDot from "@material-ui/icons/FiberManualRecord";
import { Conversion } from "fitbud/providers/conversion";
import { get } from "lodash";
import {ShowDurationValue as ShowValue} from "fitbud/views/workouts/exercise";
import { durationToColonString } from "../helperfn";
import { UserContext } from "fitbud/views/users/detail";
import {checkMax} from "fitbud/views/workouts/exercise"
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import _ from "lodash";

const MAX_EX_VIEW = 8;
const SIDES_NAMES = {
  "Per Leg": " / leg",
  "Per Arm": " / arm",
  "Per Side": " / side",
};
export const ReadOnlyTrackingFields = (props) => {
  const {
    isProgress,
    woType,
    exClasses,
    exercise,
    exData,
    currentSideName,
  }=props
  const breakLine = exercise.type !== "range" && exercise.values.length > 4;
  const { convertor } = useContext(Conversion);
  const isDurationBased=exercise.ref_type === "duration";
  if(!!exercise.track) return <NewPreview {...props}/>
  return (
    <Typography
      className={clsx(
        "text-0d0d0d",
        isDurationBased && "d-flex align-items-baseline",
        // !!isProgress && woType === "logs" && exClasses.secondaryColor
      )}
    >
      <Typography className={clsx("font_18_600 d-inline-flex flex-wrap")} >
          <>
            {exercise.values.map((i, index) => (
              <ShowValue
                type={exercise.type}
                valuesLength={(exercise.values || []).length}
                value={i}
                index={index}
                preview={true}
                parse={isDurationBased}
              />
            ))}
          </>
        <span className="font_15_500 text-nowrap mt-2px ml-4px">
        {renderExerciseType({
          exercise,
          exData,
          isProgress,
          woType,
          exClasses,
        })}
        {renderSides({ currentSideName, isProgress, woType, exClasses })}
      </span>
      </Typography>
      {breakLine && <br />}
      <span className="font_15_500">
        {renderRepsAndWeight({
          hideDot: breakLine,
          exercise,
          isProgress,
          woType,
          exClasses,
          convertor,
        })}
      </span>
    </Typography>
  );
};
const renderExerciseType = ({
  exercise,
  exData,
  isProgress,
  woType,
  exClasses,
}) => {
  if(exercise.ref_type==="duration") return null;
  let ex_type = get(exercise, "ref_type") || get(exData, "type");
  if (!ex_type) return null;
  let out = "";
  if (ex_type === "duration") {
    out = " sec";
  } else {
    out = " reps";
  }
  return (
    <span
      className={clsx(
        "font_14_500",
        // !!isProgress && woType === "logs"
        //   ? exClasses.secondaryColor
        //   : exClasses.primarySubColor
      )}
    >
      {out}
    </span>
  );
};

const renderSides = ({ currentSideName, isProgress, woType, exClasses }) => {
  // used in only non editable mood
  if (!currentSideName) return null;
  let sideValue = currentSideName[1].value;
  return (
    <span
      className={clsx(
        // "font_13_500",
        // !!isProgress && woType === "logs"
        //   ? exClasses.secondaryColor
        //   : exClasses.primarySubColor
      )}
    >
      {SIDES_NAMES[sideValue] || sideValue}
    </span>
  );
};
const renderRepsAndWeight = ({
  hideDot = false,
  exercise,
  isProgress,
  woType,
  exClasses,
  convertor,
}) => {
  let weightsData = exercise.weights || [];
  let weightType = exercise.weight_type;
  weightsData = weightsData.map((v) =>
    convertor.getDisplayValue("equipment-weight", v)
  );
  if (!weightsData.length) return null;
  if (weightsData.length) {
    return (
      <>
        {!hideDot && (
          <>
            &nbsp; <DividerDot className="fmx-10" style={{ fontSize: "5px" }} />
            &nbsp;
          </>
        )}
        <span
          className={clsx(
            "font_16_600",
            // !!isProgress && woType === "logs"
            //   ? exClasses.secondaryColor
            //   : exClasses.primarySubColor
          )}
        >
          {weightType === "range"
            ? weightsData[0] + " - " + weightsData[1]
            : weightsData.length > MAX_EX_VIEW
            ? weightsData.slice(0, MAX_EX_VIEW).join(", ") + "..."
            : weightsData.join(", ")}
          &nbsp;
          <span className="font_13_500">
            {convertor.getDisplayUnit("equipment-weight")}
          </span>
        </span>
      </>
    );
  }
  return null;
};
const isFemaleFn=(g="",fallback)=>{
  if(!g) g="";
  if(g.toLowerCase()==="other") return (fallback || '').toLowerCase()==="female";
  return g.toLowerCase()==="female";
};

const NewPreview=({exercise,...rest})=>{
  const {profile={}}=useContext(UserContext);
  const {convertor} = useContext(Conversion);
  const {features={}} = useContext(FirebaseAuthContext);
  const {exClasses,isProgress,woType,currentSideName}=rest;
  const isFemale=useMemo(()=>isFemaleFn(profile.gender,features && features.default_gender),[profile.gender,features]);
  const {track=[],type,type2}=exercise;
  const male1=(isFemale && isProgress && exercise.female1)?exercise.female1||[]:exercise.male1||[];
  const male2=(isFemale && isProgress && exercise.female2)?exercise.female2||[]:exercise.male2||[];
  const [primTrk,secTrk]=track||[];
  const isDurationBased=primTrk==="duration";
  const sideValue = get(currentSideName,[1,'value']);
  const breakLine=male1.length>4;
  return(
    <Typography
      className={clsx(
        "text-0d0d0d",
        // !!isProgress && woType === "logs" && exClasses.secondaryColor
      )}
    >
      <Typography className={clsx("font_18_600 d-inline-flex flex-wrap")} >
        {_.map(male1, (i,index)=>(
          <Values type={type} v={i} index={index} parseDuration={isDurationBased} vLength={_.size(male1)} 
          convertor={convertor} unit={primTrk}/>
        ))}
        <span className="text-nowrap ml-3px">
          {!isDurationBased && <span className={clsx("font_14_500")}>
            {convertor.getDisplayUnit(primTrk)}
          </span>}
          {!!currentSideName && <span className={clsx("font_13_500")}>
            {SIDES_NAMES[sideValue] || sideValue}
          </span>}
      </span>
      </Typography>
      {breakLine && <br />}
      {(!!secTrk && !!male2.length) && <span className="font_15_500">
        {!breakLine && <>&nbsp;<DividerDot className="fmx-10" style={{ fontSize: "5px" }}/>&nbsp;</>}
        <span className={clsx("font_16_600")}>
          {male2.map((i,index)=>(
            <Values type={type2} v={i} index={index} parseDuration={secTrk==="pace"} vLength={male2.length} 
            convertor={convertor} unit={secTrk}/>
          ))}
        </span>
        <span className="font_13_500 ml-3px">
            {convertor.getDisplayUnit(secTrk)}
        </span>
      </span>}
    </Typography>
  )
}
const Values=({type,index,v,parseDuration,vLength,convertor,unit,convertToDisplayValue=true})=>{
  let str="";
  if(convertToDisplayValue){
    str=checkMax(convertor.getDisplayValue(unit,v));
  }
  if(parseDuration){
    str=durationToColonString(str);
  }
  const isRange=type==="range";
  return(
    <span>
      {str}
      {index<(vLength-1) && <span className={isRange?"mx-6":"mr-6"}>{isRange?"-":","}</span>}
    </span>
  )
}

