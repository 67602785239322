import React, { useEffect, useContext, useRef, useCallback } from "react";
import * as Sentry from '@sentry/browser';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { AnalyticsContext } from "fitbud/providers/analytics";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import appRdxFns from 'fitbud/redux/app';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import firebase from 'firebase';
import uuid from "uuid/v4";
import moment from "moment";
import _ from 'lodash';
import { useSnackbar } from "notistack";
import { bffAddCustomer2Company, bffMailWelcomeCustomer, bffUpdateHubspot } from "fitbud/api";
import { DEFAULT_ERROR } from "fitbud/utils/constants";
import activeUsersListRdxFns from "fitbud/redux/activeUsersList";
import { HUBSPOT_PROPS } from 'fitbud/utils/constants';

const ADD_YOURSELF_AS_CLIENT_FLOW_ID = '447047ac-9d5b-46e4-9f5a-cfd812735de3';

const addTrainer2CompanyAsClient = async (cid, authUser, email, history, addedClient, showLoader, hideLoader, enqueueSnackbar, request) => {
  try {
    if (addedClient.current) {
      history.push(`/clients/active/${addedClient.current}`)
      return;
    }
    showLoader()
    const pid = `${cid}:${authUser.uid}`;
    const docSnap = await firebase.firestore().doc(`user_profiles/${cid}:${authUser.uid}`).get();
    if (!docSnap.exists) {
      const userToken = await authUser.getIdToken();
      await bffAddCustomer2Company(userToken, cid, email);
      const aplan = `${pid}:${uuid()}`
      await firebase.firestore().runTransaction(async txn => {
        const planSnap = firebase.firestore().doc(`plans/${aplan}`)
        const userSnap = firebase.firestore().doc(`user_profiles/${pid}`)
        const pack = {
          duration: 84,
          durationType: 'weeks',
          id: 'custom',
          pack_id: 'custom',
          ref_name: 'Custom',
          title: 'Custom',

        }
        const data = {
          startDate: moment().format('YYYYMMDD'),
          duration: 84,
          durationType: 'weeks',
        }
        await txn.set(planSnap, {
          _m: true,
          plan_state: 'activated',
          cid,
          userProfileId: pid,
          ...data,
          purchase: {
            pack
          },
          weeks: _.range(12).map(() => uuid()),
        });
        await txn.update(userSnap, {
          aplan,
          onboarding_complete: true,
          current_plan_status: 'activated',
          endDate: moment().add(83, 'days').format('YYYYMMDD'),
          assigned_till: moment().subtract(1, 'day').format('YYYYMMDD'),
          ...data,
          plan_assign: {
            pack
          },
        });
      });
      bffUpdateHubspot({
        [HUBSPOT_PROPS.CLIENT_ADDED]: moment().format('YYYY-MM-DD'),
      });
      bffMailWelcomeCustomer(userToken, cid, authUser.uid).catch(console.log)
    }
    addedClient.current = pid;
    await new Promise(r => setTimeout(r, 2000));
    setTimeout(() => request(true) , 2000)
    window.Appcues && window.Appcues.track("ADDED_TRAINER_AS_CLIENT");
    history.push(`/clients/active/${pid}`)
  } catch (error) {
    console.log(error)
    Sentry.captureException(error);
    enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
  }
  hideLoader()
}

const Appcues = (props) => {
  const { cid, uid, email, name, dimensions } = useContext(AnalyticsContext);
  const { authUser } = useContext(FirebaseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);
  const { request } = activeUsersListRdxFns(d);
  const addedClient = useRef(false);


  // if (process.env.NODE_ENV !== 'production') return null;
  const throttleAppCues = useCallback(_.throttle((event) => {
    console.log("Appcues started a flow with ID " + event.flowId);
    if (event.flowId === ADD_YOURSELF_AS_CLIENT_FLOW_ID) {
      addTrainer2CompanyAsClient(cid, authUser, email, history, addedClient, showLoader, hideLoader, enqueueSnackbar, request)
    }
  }, 500), [cid, email])

  useEffect(() => {
    if (!window.Appcues) return;
    if (!cid || !uid) return;
    const {cid: _ignore1, uid: _ignore2, ...props} = dimensions || {};
    window.Appcues.identify(email, {email, name, ...props});
    window.Appcues.on("flow_started", throttleAppCues);
  }, [cid, uid, email, name, dimensions, location, throttleAppCues]);

  return (
    <Helmet script={[{ 
      type: 'text/javascript', 
      innerHTML: 'window.AppcuesSettings = {enableURLDetection: true}' 
    }]} >
      <script src="//fast.appcues.com/120068.js"></script>
    </Helmet>
  );
};

export default Appcues;
