import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer,
  useMemo
} from "react";
import _ from "lodash";
import clsx from "clsx";
import firebase from "fitbud/firebase";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import smile from "fitbud/images/smile.svg";
import send_icon from "fitbud/images/send_icon.svg";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import { WorkerContext } from "fitbud/providers/workerProvider";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import * as Sentry from '@sentry/browser';
import { DEFAULT_ERROR, DISABLED_CHAT_TEXT } from "fitbud/utils/constants";
import {
  downloadFile} from "fitbud/utils/helpers";
import {
  getImageMeta,
  getVideoMeta
} from "fitbud/utils/services";
import moment from "moment";
import "emoji-mart/css/emoji-mart.css";
import { AvatarImage } from "fitbud/views/users/header";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Collapse, Badge, Menu, MenuItem, Popover } from "@material-ui/core";
import Mic from '@material-ui/icons/Mic';
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  InputBase,
  ListItemText,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { Picker } from "emoji-mart";
import appRdxFns from "fitbud/redux/app";
import withWidth from "@material-ui/core/withWidth/index";
import ScrollToBottom from "fitbud/views/chats/scrollToBottom";
import PageNotFound from "fitbud/views/pageNotFound";
import PdfIcon from "fitbud/images/pdf.svg";
import DocIcon from "fitbud/images/doc.svg";
import ExcelIcon from "fitbud/images/xls.svg";
import AudioIcon from "fitbud/images/audio.svg";
import MediaPreview from "./mediaPreview";
import AudioRecorder from "../../components/audio-recorder/audioRecorder";
import AudioPlayer from "fitbud/components/Audio";
import VideoOpener from "fitbud/components/videoOpener";
import { resizeAndUpload } from "../broadcasts/broadcastView";
import { getUserLastSeen } from "../users/helpers";
import { useToggle } from "fitbud/hooks/form";
import Confirmation from "fitbud/components/confirmationDialog";
import Message from './message';
import TargetMessagePreview from "./targetMsgPreview";
import { getStaffSettings } from "../groupChat/helper";
import { findNewMessageIndex, splitMessages } from "../groupChat/groupChatView";
import AIScheduleGenerator from "../schedulePlanner/aiScheduleGenerator";

const storageRef = firebase.storage().ref();
const CHAT_FILE_SIZE_DEFAULT = 3; // MB

export const ShowDate = ({ date }) => {
  const classes = useStyles();
  if(!date) return null;
  return (
    <Typography
      variant="caption"
      className={`${classes.dateTab} d-block fpx-20 fpy-10 text-center fmb-20`}
    >
      {moment(date).format("ddd, D MMM YYYY")}
    </Typography>
  );
};

export const isMessageReacted = (msgData, uid) => {
  return msgData && msgData.react && msgData.react[uid];
}

const BoxModeMsgPop = ({ msgs }) => {
  return (
    <Badge
      badgeContent={msgs}
      className="text-danger"
      children={""}
      classes={{ badge: "bg-white fmr-15" }}
    />
  );
};

export const getItemSvg = (ext) => {
  let svg = "";
  const fileExt = ext.toLowerCase();
  switch (fileExt) {
    case "pdf":
      svg = PdfIcon;
      break;
    case "doc":
    case "docx":
      svg = DocIcon;
      break;
    case "xls":
    case "xlsx":
      svg = ExcelIcon;
      break;
    case "audio":
      svg = AudioIcon;
      break;
    default:
      svg = "";
  }
  return svg;
};

export const AttachmentList = ({id, attachmentData}) => {
  const [state, setState] = useState("");
  const [error, setError] = useState({message: 'Loading ...'});
  const {body, attachment: {identifier, completed, duration} = {}, type} = attachmentData;
  const ext = identifier.split(".").pop().toLowerCase();
  const classes = useStyles();
  const base_path = _.get(attachmentData, 'attachment.base_path', false);
  useEffect(() => {
    if (!completed) return;
    const url = base_path ? `${base_path}/chats/attachment/${identifier}` : `user_profiles/${id}/chats/attachment/${identifier}`;
    setTimeout(() => storageRef.child(url)
      .getDownloadURL()
      .then((val) => {
        setState(val);
        setError(false);
      }).catch(e => {
        console.error(e);
        if (e.code === 'storage/object-not-found')
          setError({message: 'File missing ❌'});
        else
          setError(e);
    }), 0);
  }, [id, identifier, completed, attachmentData, base_path]);
  // When attachment is a video
  if(type === 'video'){
    return (
      <VideoOpener
        url={state}
        attachment={attachmentData.attachment}
      />
    );
  }
  // When attachment is a Audio
  if(type === 'audio'){
    if(!state) return <CircularProgress />; //Show loading while fetching URL
    return (
      <AudioPlayer src={state} duration={duration} minified />
    );
  }
  return (
    <ListItemText>
      { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
      <a href={error || !state ? '#' : state} target={error || !state ? '' : '_blank'}
        rel="noopener noreferrer" className={classes.attachmentAnchor}>
        <img className="fmr-10" width="25px" alt={body} src={getItemSvg(ext)} />{error ? error.message : body}
      </a>
    </ListItemText>
  )
}
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    "& textarea": {
      maxHeight: "100px",
      overflow: "scroll!important",
      minHeight: "20px"
    }
  },
  rootPaper: {
    padding: "10px",
    backgroundColor: "#f2f2f2",
    borderRadius: "0",
    border: "none",
    position: "relative"
  },
  input: {
    // width: "90%",
    borderRadius: "25px",
    backgroundColor: "#ffffff",
    padding: "10px 25px 10px 20px",
  },
  toolbar: {
    borderLeft: "none"
  },
  noMinHeight: {
    minHeight: "unset"
  },
  chatArea: {
    background: "#ffffff",
    flex: "1",
    minHeight: "0",
    overflow: "scroll",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 187px)"
    }
  },
  focusedMessage: {
    "& .msgBubble": {
      backgroundColor: "#E0ECFE",
    }
  },
  chatLoader:{
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  dateTab: {
    width: "fit-content",
    backgroundColor: "#dfecff",
    borderRadius: "16px",
    margin: "auto",
    color: "#4089f7",
    marginTop: "30px",
  },
  emojis: {
    position: "absolute",
    bottom: 70,
    zIndex: 1200,
    left: 10,
    cssFloat: "right",
    "& .emoji-mart-preview": {
      display: "none"
    }
  },
  reactionPicker: {
    "& .emoji-mart-preview": {
      display: "none"
    }
  },
  wrapperInner: {
    display: "flex",
    flexDirection: "column"
  },
  textareaInput: {
    maxHeight: "100px",
    overflow: "scroll",
    minHeight: "20px"
  },
  wrapper: {
    minHeight: "0px",
    width: "100%"
  },
  collapseHeight: {
    maxHeight: "320px"
  },
  attachmentAnchor: {
    color: "#000",
    "&:hover": {
      textDecoration: "none"
    }
  },
  audioPreview:{
    width: "100%",
    minHeight: "60%",
    bottom: 0,
    backgroundColor: "white"
  }
}));

const currentTime = moment();

function reducer(state, action) {
  const { type, payload, cid } = action;
  const chatarea = document.getElementById("chatArea");
  const last_msg = document.getElementById("messagesEnd");
  const chatAreaBottom = chatarea.getBoundingClientRect().bottom;
  const lastMsgBottom = last_msg.getBoundingClientRect().bottom;

  let idToScroll = "messagesEnd";
  if (lastMsgBottom - chatAreaBottom > 100 && !!state.messages.length) {
    if (type === "added" && payload[0] && payload[0].data().authorId !== cid) {
      idToScroll = null;
    }
  }
  if(state.secondaryMsgs.length) {
    idToScroll = null
  }
  switch (type) {
    case "added":
      const newMsgIndex = findNewMessageIndex(state.messages, currentTime);
      const { leftMessages: oldMsgs, rightMessages: remainingMsgs } = splitMessages(state.messages, newMsgIndex);


      const newMessages = _.sortBy([...remainingMsgs, ...payload], [
        (o) => o.data({ serverTimestamps: 'estimate' }).timestamp.seconds,
        (o) => o.data({ serverTimestamps: 'estimate' }).timestamp.nanoseconds,
        (o) => o.id,
      ]);
      
      if(payload.length === 0) idToScroll = null

      return {
        ...state,
        messages: [...oldMsgs, ...newMessages],
        lastFetchedMsg: !!state.messages.length
          ? state.messages[0]
          : payload[0],
        msgScroll: {
          id: idToScroll,
          block: "end",
          behavior: "instant"
        },
        seen: false
      };
    case "modified":{
      const newState = {
        ...state,
        messages: [
          ...state.messages.map(msg => {
            if (msg.id === payload.id) return payload;
            else return msg;
          })
        ],
        msgScroll: { id: null },
        seen: false
      };

      if(state.secondaryMsgs.length > 0) {
        newState.secondaryMsgs = [
          ...state.secondaryMsgs.map(msg => {
            if (msg.id === payload.id) return payload;
            else return msg;
          })
        ]
      }
      return newState
    }
    case "metaChanges":{
      const newState = {
        ...state,
        messages: state.messages.map(
          obj => payload.find(o => o.id === obj.id) || obj
        ),
        msgScroll: { id: null },
      };
      if(state.secondaryMsgs.length > 0) {
        newState.secondaryMsgs = state.secondaryMsgs.map(
          obj => payload.find(o => o.id === obj.id) || obj
        )
      }
      return newState
    }
    case "fetchPrevMsgs":
      if(state.secondaryMsgs.length) {
        return {
          ...state,
          secondaryMsgs: [...payload.reverse(), ...state.secondaryMsgs],
          lastFetchedMsg: !!payload.length ? payload[0] : null,
          msgScroll: {
            id: state.secondaryMsgs[0].id,
            block: "end",
            behavior: "instant"
          }
        }
      }
      return {
        ...state,
        messages: [...payload.reverse(), ...state.messages],
        lastFetchedMsg: !!payload.length ? payload[0] : null,
        msgScroll: {
          id: state.messages[0].id,
          block: "end",
          behavior: "instant"
        }
      };
    case "fetchNextMsgs":
      const overlapIndex = state.messages.findIndex((msg) => msg.id === payload[payload.length - 1].id);
      const secondaryMsgsLength = state.secondaryMsgs.length;
      if(overlapIndex > -1) {
        const newPayload = payload.slice(0, payload.length - 1 - overlapIndex);

        return {
          ...state,
          messages: [...state.secondaryMsgs, ...newPayload, ...state.messages],
          lastFetchedMsg: state.secondaryMsgs[0],
          latestFetchedMsg: null,
          msgScroll: {
            id: state.secondaryMsgs[secondaryMsgsLength - 1].id,
            block: "end",
            behavior: "smooth"
          },
          secondaryMsgs: []
        }
      }
      return {
        ...state,
        secondaryMsgs: [...state.secondaryMsgs, ...payload],
        latestFetchedMsg: payload[payload.length - 1],
        msgScroll: {
          id: state.secondaryMsgs[secondaryMsgsLength - 1].id,
          block: "end",
          behavior: "smooth"
        }
      };
    case "fetchSecondaryMsgs": 
      return {
        ...state,
        secondaryMsgs: [...payload],
        lastFetchedMsg: payload[0],
        latestFetchedMsg: payload[payload.length - 1],
        msgScroll: {
          id: action.scrollToId,
          block: "center",
          behavior: "instant"
        }
      };
    case "resetChat":
      return {
        ...state,
        secondaryMsgs: [],
        msgScroll: {
          id: "messagesEnd",
          block: "end",
          behavior: "smooth"
        },
        lastFetchedMsg: state.messages[0],
        latestFetchedMsg: null
      }
    case "setSeen":
      return {
        ...state,
        seen: true
      };
    default:
      return state;
  }
}
const initialState = {
  messages: [],
  lastFetchedMsg: null,
  latestFetchedMsg: null,
  msgScroll: {
    id: "messagesEnd",
    block: "end",
    behavior: "smooth"
  },
  seen: false,
  secondaryMsgs: []
};
const ChatView = props => {
  const classes = useStyles();
  const isScrolled = useRef(false);
  const [show, setShow] = useState(false);
  const inputEl = useRef(null);
  const textInput = useRef(null);
  const focusedMsg = useRef(null);
  const { cid, comp, userProfile:{uid, features = {} } = {}, authUser } = useContext(FirebaseAuthContext);
  const sendOnEnter = features && features.chatEnterKey;
  const { markRead } = useContext(WorkerContext);
  const [user, setUser] = useState({});
  const [flagged, setFlag] = useState(false);
  const [flagging, setFlagging] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [previewFile, setPreviewFile] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showEmoji, setShowEmoji] = useState(false);
  const [totalMessages, setTotalMessages] = useState(0);
  const [isChatWinOpen, updateChatWinOpen] = useState(!props.boxMode);
  const [isActiveWindow, setWindowActive] = useState(true);
  const [showAudio, setShowAudio] = useState(false);
  const persistChat = props.persistChat && props.chatKey;
  const [aiPrompt, setAIPrompt] = useState({});

  const aiChat = props.chatType === 'ai';
  console.debug('aiChat', aiChat);

  const [newMsg, setNewMsg] = useState(() => {

    let defaultMsg = {
      authorId: cid,
      body: null,
      type: null,
      timestamp: null,
      _uat: null,
      media: {
        completed: null,
        imageData: null,
        identifier: null,
        aspect: null
      }
    }
    if(!persistChat) return defaultMsg;
    
    const msg = JSON.parse(sessionStorage.getItem(props.chatKey));
    if(msg) {
      msg.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      msg._uat = firebase.firestore.FieldValue.serverTimestamp();
    }
    return msg || defaultMsg;
  });
  const [replyData, setReplyData] = useState(null);
  const [isValidId, setIdStatus] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reactAnchorEl, setReactAnchorEl] = useState(null);
  const [downloadResource, setDownloadResource] = useState({type: '', data: {}, id: ''});
  const [deleteConfirm, toggleDeleteConfirm] = useToggle();
  const [isChatLoading, setChatLoading] = useState(false);
  const [fetchDir, setFetchDir] = useState("prev");
  const [viewWidth, setViewWidth] = useState(0);

  const { messages, secondaryMsgs, lastFetchedMsg, msgScroll, seen, latestFetchedMsg } = state;
  const displayMsgs = secondaryMsgs.length ? secondaryMsgs : messages;
  const latestMsg = displayMsgs && displayMsgs[displayMsgs.length - 1];

  const targetMessageUserName = useMemo(() => {
    if(!replyData) return null;
    if(replyData.authorId === cid) {
      if(authUser.uid === replyData.staffId) {
        return "You"
      }
      let name = getStaffSettings(comp.data(), replyData.staffId, 'label');
      if(!name) {
        name = "Team member"
      }
      return name
    }
    return user.profile.name
  },[authUser.uid, cid, replyData, user, comp])

  async function beginDownload() {
    showLoader();
    try {
      const { type, data, id } = downloadResource;
      let downloadUrl;
      if (type === 'image') {
        downloadUrl = await getImageDownloadUrl(data);
      } else {
        downloadUrl = await getDownloadUrl(props.id, data);
      }
      if (!downloadUrl) throw new Error('invalid url');
      await downloadFile(downloadUrl, type === 'other' ? data.body : id);
      hideLoader();
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      hideLoader();
    }
  }

  function handleReply() {
    const target = displayMsgs.find(msg => msg.id === focusedMsg.current.id);
    setReplyData({ ...target.data(), replyId: target.id, reply: null });
    setTimeout(() => {
      textInput.current.focus();
    }, 0);
  }
  
  useEffect(() => {
    const chatarea = document.getElementById("chatArea");

    chatarea.addEventListener("scroll", decideToShowUnread);
    document.addEventListener("visibilitychange", tabChanged);
    if (!!props.users) {
      const user = props.users.filter(usr => props.id === usr.uid)[0];
      if (!user) {
        setIdStatus(false);
      } else {
        setUser({ ...user });
        markRead({ cid, keyName: "chats", user: { ...user, _id: props.id } });
      }
    }
    return () => {
      chatarea && chatarea.removeEventListener("scroll", decideToShowUnread);
      document.removeEventListener("visibilitychange", tabChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //on mount update last seen and read count
  useEffect(() => {
    if (!!isChatWinOpen) {
      if (!!props.id && user.total_messages && isActiveWindow) {
        markRead({ cid, keyName: "chats", user: { ...user, _id: props.id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.total_messages, isChatWinOpen, isActiveWindow]);

  // listener for changes in chat doc
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("user_profiles")
      .doc(props.id)
      .onSnapshot(async function(doc) {
        setUser({ ...doc.data() });
      });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect( () => {
    async function fetchMsgs() {
      const snapshot = await firebase.firestore()
      .collection(`user_profiles/${props.id}/messages`)
      .orderBy("timestamp", "desc")
      .limit(10).get()

      dispatch({ type: 'added', payload: snapshot.docs.reverse(), cid})
    }

    fetchMsgs()
  },[props.id, cid])

  // listener for changes in messages docs
  useEffect(() => {
    const collectionName = aiChat ? 'companies' : 'user_profiles';
    const messageCollectionName = aiChat ? 'ai_messages' : 'messages';
    const chatDocId = aiChat ? cid : props.id;
    console.debug('collectionName', collectionName, messageCollectionName);
    const unsubscribe = firebase
      .firestore()
      .collection(collectionName)
      .doc(chatDocId)
      .collection(messageCollectionName)
      .where("_uat", ">", firebase.firestore.Timestamp.fromDate(new Date()))
      .orderBy("_uat", "desc")
      .onSnapshot({ includeMetadataChanges: true }, querySnapshot => {
        if (!!querySnapshot.docChanges().length) {
          const newMessages = [];
          querySnapshot.docChanges().forEach(function(change) {
            const msg = change.doc.data({serverTimestamps: "estimate"})
            const lastMsg = state.messages[state.messages.length - 1]?.data();
            const latestMsgTimestamp = moment(lastMsg?.timestamp?.toDate() || currentTime);
            const timestamp = moment(msg.timestamp.toDate())
            if (change.type === "added" && timestamp.isAfter(latestMsgTimestamp)) {
              newMessages.unshift(change.doc);
            } else {
              dispatch({ type: "modified", payload: change.doc });
            }
          });
          dispatch({ type: "added", payload: newMessages, cid });
        } else {
          dispatch({ type: "metaChanges", payload: querySnapshot.docs });
        }
      });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const tabChanged = () => {
    if (document.visibilityState === "visible") {
      setWindowActive(true);
    } else {
      setWindowActive(false);
    }
  };
  const decideToShowUnread = () => {
    const chatarea = document.getElementById("chatArea");
    const last_msg = document.getElementById("messagesEnd");
    let chat;
    setUser(prev => {
      chat = prev;
      return prev;
    });
    setShow(
      chatarea &&
        last_msg &&
        chatarea.getBoundingClientRect().bottom <
          last_msg.getBoundingClientRect().bottom
    );
    if (
      chatarea &&
      last_msg &&
      last_msg.getBoundingClientRect().bottom -
        chatarea.getBoundingClientRect().bottom <=
        100
    ) {
      setTotalMessages(chat.total_messages);
      setShow(false);
    }
  };

  const scrollToItem = ({id, block = 'end', behavior = 'smooth'}) => {
    if (!id)  return;
      document
        .getElementById(id)
        .scrollIntoView({ behavior, block });
  };

  const scrollToMessage = async (id) => {
    if(!id) return;
    const msg = displayMsgs.find(msg => msg.id === id);

    if(msg) {
      scrollToItem({ id, block: "center", behavior: "smooth" });
    } else{
      setChatLoading(true);
      const chatArea = document.getElementById("chatArea");

      if(chatArea.scrollTop >= chatArea.scrollHeight - chatArea.clientHeight) {
        chatArea.scrollTop -= 2;
      }
      const collectionName = aiChat ? 'companies' : 'user_profiles';
      const messageCollectionName = aiChat ? 'ai_messages' : 'messages';
      const chatDocId = aiChat ? cid : props.id;
      const msgDoc = await firebase.firestore().collection(collectionName).doc(chatDocId)
        .collection("messages").doc(id).get();

      if(!msgDoc.exists) {
        enqueueSnackbar("This message is deleted", { variant: 'error' });
        setChatLoading(false);
        return;
      }
      // update to use collectionNames

      const prevMessages = firebase
      .firestore()
      .collection(collectionName)
      .doc(chatDocId)
      .collection(messageCollectionName)
      .orderBy("timestamp", "desc")
      .startAfter(msgDoc)
      .limit(5)
      .get();
      const nextMessages = firebase
      .firestore()
      .collection(collectionName)
      .doc(chatDocId)
      .collection(messageCollectionName)
      .orderBy("timestamp", "asc")
      .startAfter(msgDoc)
      .limit(5)
      .get();

      const [prev, next] = await Promise.all([prevMessages, nextMessages]);

      dispatch({ 
        type: "fetchSecondaryMsgs", 
        payload: [...prev.docs.reverse(), msgDoc, ...next.docs], 
        scrollToId: id 
      });
      setChatLoading(false);
    }

    const msdNode = document.getElementById(id);
    if(msdNode) {
      msdNode.classList.add(classes.focusedMessage)
      setTimeout(() => {
        msdNode.classList.remove(classes.focusedMessage)
      }, 1000)
    }
  };

  useEffect(() => {
    setFlagging(false);
    setFlag(!!user && !!user.systags && user.systags.length && user.systags.includes('flagged'));
  }, [user]);
  const toggleFlag = () => {
    setFlagging(true);
    props.toggleFlag();
  };

  useEffect(() => {
    if (
      displayMsgs.length &&
      latestMsg.data().authorId === cid &&
      user[user.uid] &&
      user[user.uid].read_count === user.total_messages
    ) {
      dispatch({ type: "setSeen" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, displayMsgs.length, cid]);

  // on mount scroll to bottom
  useEffect(() => {
    const {id, block, behavior} = msgScroll || {}
    if (!id) return;
    scrollToItem({id, block, behavior});
  }, [displayMsgs.length, seen]);

  const onChange = e => {
    const { authorId } = newMsg;
    setNewMsg({
      body: e.target.value,
      // timestamp: new Date(),
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      _uat: firebase.firestore.FieldValue.serverTimestamp(),
      type: "text",
      authorId,
      ...(aiChat ? {ai: true, uid: props.id} : {}),
    });
  };

  const handleBlur = (e) => {
    if(!persistChat) return;
    sessionStorage.setItem(props.chatKey, JSON.stringify(newMsg));
  }
  
  const addEmoji = e => {
    let emoji = e.native;
    const { authorId } = newMsg;
    let new_msg_body = newMsg.body ? newMsg.body + emoji : emoji;
    setNewMsg({
      body: new_msg_body,
      // timestamp: new Date(),
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      _uat: firebase.firestore.FieldValue.serverTimestamp(),
      type: "text",
      authorId
    });
  };

  const handleshowEmojis = () => {
    setShowEmoji(true);
    textInput.current.focus();
  };
  const handleClickAway = () => {
    setShowEmoji(false);
    textInput.current.focus();
    textInput.current.setSelectionRange(
      textInput.current.value.length,
      textInput.current.value.length
    );
  };

  useEffect(() => {
    textInput.current.focus();
  },[showAudio])

  const sendMessage = async msg => {
    if(!msg) return;
    if(!msg.authorId) msg.authorId = cid;
    if(!msg.timestamp) msg.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    if(!msg._uat) msg._uat = firebase.firestore.FieldValue.serverTimestamp();
    if (msg.body) msg.body = msg.body.trim();
    if (msg.body === "" && (msg.type === 'text' || msg.type === 'video')) return;
    if(uid)msg.staffId=uid;
    if(replyData) {
      msg.reply = replyData;
      setReplyData(null);
    }
    
    const collectionName = aiChat ? 'companies' : 'user_profiles';
    const messageCollectionName = aiChat ? 'ai_messages' : 'messages';
    const chatDocId = aiChat ? cid : props.id;

    setTotalMessages(prev => (user.total_messages || prev) + 1);
    const result = await firebase
      .firestore()
      .collection(collectionName)
      .doc(chatDocId)
      .collection(messageCollectionName)
      .add({ ...msg });
    markRead({ cid, keyName: "chats", user: { ...user, _id: props.id } });
    handleBottomScroll();
    // use message id and pass it to genai api.
    if (aiChat && result.id && msg.authorId !== 'FitBot') {
      console.log("AI Chat", result.id, result);
      setAIPrompt({...msg, id: result.id});
    }
    
    if (!aiChat) markRead({ cid, keyName: "chats", user: { ...user, _id: props.id } });
  };

  const handleKeyPress = (e, isButtonClick=false) => {
    if(!!isButtonClick){
      e.preventDefault();
      let _newMsg = {...newMsg};
      setNewMsg({});
      if(persistChat) {
        sessionStorage.removeItem(props.chatKey);
      }
      sendMessage(_newMsg);
      return;
    }
    if (sendOnEnter && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!e.target.value.trim()) return;
      let _newMsg = {...newMsg};
      setNewMsg({});
      if(persistChat) {
        sessionStorage.removeItem(props.chatKey);
      }
      sendMessage(_newMsg);
    }
  };

  const onScroll = e => {
    isScrolled.current =
    e.target.scrollTop < e.target.scrollHeight - e.target.clientHeight;
    if (fetching) return;
    if (e.target.scrollTop <= 0) {
      //scroll previous messages
      setFetching(true);
      setFetchDir("prev");
      fetchPrevMessages();
    }
    if(e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight - 1) {
      setFetching(true);
      setFetchDir("next");
      fetchNextMessages();
    }
  };

  const fetchPrevMessages = async () => {
    if (!lastFetchedMsg) {
      setFetching(false);
      return;
    }
    const collectionName = aiChat ? 'companies' : 'user_profiles';
    const messageCollectionName = aiChat ? 'ai_messages' : 'messages';
    const chatDocId = aiChat ? cid : props.id;
    const fetchedMsgs = await firebase
      .firestore()
      .collection(collectionName)
      .doc(chatDocId)
      .collection(messageCollectionName)
      .orderBy("timestamp", "desc")
      .startAfter(lastFetchedMsg)
      .limit(10)
      .get();

    setFetching(false);
    dispatch({ type: "fetchPrevMsgs", payload: fetchedMsgs.docs });
  };

  const fetchNextMessages = async () => {
    if(!latestFetchedMsg || secondaryMsgs.length === 0) {
      setFetching(false);
      return
    }
    const fetchedMsgs = await firebase
      .firestore()
      .collection("user_profiles")
      .doc(props.id)
      .collection("messages")
      .orderBy("timestamp", "asc")
      .startAfter(latestFetchedMsg)
      .limit(10)
      .get();

      setFetching(false);
      dispatch({ type: "fetchNextMsgs", payload: fetchedMsgs.docs})
  }

  const handleBottomScroll = () => {
    const chatarea = document.getElementById("chatArea");
    if(secondaryMsgs.length === 0) {
      chatarea.scrollTo({ top: (0, chatarea.scrollHeight), behavior: "smooth" });
    } else {
      dispatch({ type: "resetChat" });
    }
  }

  const addedFile = async e => {
    e.persist();

    if (
      !e ||
      !e.currentTarget ||
      !e.currentTarget.files ||
      e.currentTarget.files.length < 0
    )
      return;
    const file = e.currentTarget.files[0];
    const parts = file.type.split("/");
    const isImage = parts.length && parts[0] === 'image';
    const isVideo = parts.length && parts[0] === 'video';
    e.currentTarget.value = "";
    if (isImage) {
      const { aspect, width, height } = await getImageMeta(file);
      setPreviewFile({ file, isImage, aspect, width, height });
    }else if(isVideo){
      const { aspect, width, height, duration } = await getVideoMeta(file);
      setPreviewFile({ file, isVideo, aspect, width, height, duration });
    } else {
      setPreviewFile({ file, isImage });
    }
  };

  const cancelPreview = () => {
    setPreviewFile({});
    setShowAudio(false)
  };

  const cancelReply = () => {
    setReplyData(null);
  }

  const handleCallback = (childData) => {
    setPreviewFile(childData)
  }

  const {hideLoader, showLoader} = props;
  const handleSend = async () => {
    if (!previewFile.file) return;
    showLoader();
    try {
      const path = `user_profiles/${props.id}/chats/${previewFile.isImage ? 'original' : 'attachment'}`
      await resizeAndUpload(previewFile, cid, sendMessage, path, false, null, uid);
      if(previewFile.isAudio) setShowAudio(false);
      setPreviewFile({});
      hideLoader();
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
      hideLoader();
    }
  };
  const {appConfig, enqueueSnackbar} = props;
  useEffect(() => {
    if (!previewFile || !previewFile.file) return;
    let fileSize = (previewFile && previewFile.file.size) || 0;
    const maxSize = (appConfig && appConfig.company_chat_file_max_size) || CHAT_FILE_SIZE_DEFAULT;
    if (!previewFile.isVideo && !previewFile.isAudio && !previewFile.isImage && fileSize > 1024 * 1024 * maxSize) {
      enqueueSnackbar(`Document size should not be more than ${maxSize} MB`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    const { chat_config } = comp.data() || {};
    const maxVideoSize = (chat_config && chat_config.company_max_video_size) || 16; // in MB
    const maxVideoDuration = (chat_config && chat_config.company_max_video_duration) || 90; // in seconds
    if(previewFile.isVideo && fileSize > 1024 * 1024 * maxVideoSize) {
      enqueueSnackbar(`Video file size should not be more than ${maxVideoSize} MB`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    if(previewFile.isVideo && previewFile.duration >  maxVideoDuration) {
      enqueueSnackbar(`Video file duration should not be more than ${maxVideoDuration} seconds`, { variant: "error" });
      setPreviewFile({});
      return;
    }
    if (!previewFile.isImage && !previewFile.isVideo && !previewFile.isAudio) {
      const path = `user_profiles/${props.id}/chats/attachment`
      resizeAndUpload(previewFile, cid, sendMessage, path, false, null, uid);
    }
  }, [previewFile, appConfig, enqueueSnackbar]); // eslint-disable-line react-hooks/exhaustive-deps

  let show_date = false;
  let date1;
  if (!!displayMsgs  && !!displayMsgs.length) {
    show_date = true;
    /*When ChatView is Open and new message arrive then, then messages added to the state two times as a result render two times, first time 
    without timestamp and second time with timestamp.In first time, message come with having no timestamp key so in that case use default new Date() to handle dom fluctuations :: Paritosh
    */
    let timestamp_current = (displayMsgs[0].data().timestamp && displayMsgs[0].data().timestamp.toMillis())|| new Date();
    date1 = moment(timestamp_current).local();
  }
  let ext = previewFile &&  previewFile.file ? (previewFile.file.name || "").split(".").pop().toLowerCase() : null;
  const unread = Math.max(user.total_messages - totalMessages || 0, 0);
  const boxModeUnread =
    user.total_messages && user[cid]
      ? user.total_messages - user[cid]["read_count"]
      : 0;
  function toggleChatWindow(val = false) {
    const chatarea = document.getElementById("chatArea");
    if (val) chatarea.scrollTo({ top: (0, chatarea.scrollHeight) });

    updateChatWinOpen(val);
  }
  const handleViewInMode = id => {
    props.boxMode ? props.openChat(id) : props.viewProfile(id);
  };

  const handleOpenMessageOption = (e, msg) => {
    const msgData = msg.data();
    focusedMsg.current = {...msgData, id: msg.id};
    const image_base_path = _.get(msgData, 'media.base_path', false);

    const downloadResource = {
        id: msg.id,
        type: 'text',
    };
    if (msgData.media) {
        downloadResource.type = 'image';
        downloadResource.data = {
            identifier: msgData.media.identifier,
            storageFolderRef: !!image_base_path ? `${image_base_path}/chats` : `user_profiles/${props.id}/chats`,
        };
    } else if (msgData.type === 'attachment' || msgData.type === 'audio' || msgData.type === 'video') {
        downloadResource.type = 'other';
        downloadResource.data = msgData;
    }
    
    setDownloadResource(downloadResource)
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteMessage = (e) => {
    toggleDeleteConfirm();
    const id = focusedMsg.current?.id;
    if (!id) return;
    e.preventDefault();
    showLoader();
    firebase.firestore()
      .doc(`user_profiles/${props.id}/messages/${id}`)
      .update( { deleted: true, _uat: firebase.firestore.FieldValue.serverTimestamp() }).then(() => {
        hideLoader();
      });
  }

  const reactMessage = (e) => {
    const emoji = e.native;
    setReactAnchorEl(null);
    const id = focusedMsg.current.id;
    firebase.firestore().doc(`user_profiles/${props.id}/messages/${id}`)
      .update({ 
        [`react.${uid}`]: {
        text: emoji,
        _uat: firebase.firestore.FieldValue.serverTimestamp()
      },
      _uat: firebase.firestore.FieldValue.serverTimestamp()
    })
  }

  const unReactMessage = (id) => {
    firebase.firestore().doc(`user_profiles/${props.id}/messages/${id}`)
      .update({ 
        [`react.${uid}`]: firebase.firestore.FieldValue.delete(), 
        _uat: firebase.firestore.FieldValue.serverTimestamp()
      })
  }

  const handleReact = (e, msgData) => {
    if( msgData?.react?.[uid]) {
      unReactMessage(msgData.id);
    } else {
      setReactAnchorEl(e.currentTarget)
    }
  }

  useEffect(() => {
    const chatarea = document.getElementById("chatArea");
    function handleResize() {
      const chatAreaWidth = chatarea.getBoundingClientRect().width;
      setViewWidth(chatAreaWidth)
    }
    
    if(chatarea) {
      setViewWidth(chatarea.getBoundingClientRect().width)
      window.addEventListener("resize", handleResize)
    }
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  let last_active = getUserLastSeen(user);
  if (!isValidId) return <PageNotFound key="chat" />;
  return (
    <div
      className={`${classes.root} flex-fill d-flex flex-column h-100 position-relative`}
      style={{ flex: "1" }}
    >
      {isChatLoading && (
        <div className="white-overlay d-flex justify-content-center align-items-center">
          <CircularProgress />
        </div>
      )}
      {!!props.boxMode ? (
        <AppBar
          position="static"
          color={props.boxMode ? "primary" : "inherit"}
          className={props.boxMode && " rounded-top"}
          onClick={() => (props.boxMode ? toggleChatWindow(true) : null)}
        >
          <Toolbar
            className={clsx(
              "d-flex justify-content-between align-items-center",
              "border-bottom",
              classes.toolbar,
              props.boxMode && classes.noMinHeight,
              props.boxMode && "fp-10"
            )}
          >
            <div className="d-flex fp-5" style={{ width: "65%" }}>
              <AvatarImage
                src={user && user.profile && user.profile["image_data"]}
                base64={true}
                size={props.boxMode ? "thumbnail" : "small"}
                className="fmr-20 border"
                name={user && user.profile && user.profile["name"]}
                alt={(user && user.profile && user.profile["name"]) || " "}
              />
              <div
                className="d-flex flex-column justify-content-center"
                style={{ flexGrow: "1" }}
              >
                <Typography
                  variant={props.boxMode ? "caption" : "h3"}
                  className={clsx(props.boxMode ? "text-truncate w-75" : "")}
                >
                  {user && user.profile && user.profile.name}
                </Typography>
                {last_active && (
                  <Typography className="font_10_500">
                    Active{" "}
                    {moment(last_active).fromNow() === "a few seconds ago"
                      ? ""
                      : moment(last_active).fromNow()}
                  </Typography>
                )}
              </div>
            </div>

            {props.boxMode && !isChatWinOpen && boxModeUnread > 0 && (
              <BoxModeMsgPop msgs={boxModeUnread} />
            )}
            {isChatWinOpen && (
              <div>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleViewInMode(props.id);
                  }}
                  className={clsx(
                    isChatWinOpen ? "visible" : "invisible",
                    "fmr-5 fp-5"
                  )}
                >
                  <OpenInNewIcon
                    className={clsx(
                      props.boxMode ? "text-white" : "text-black"
                    )}
                  />
                </IconButton>
                {props.boxMode ? (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      toggleChatWindow();
                    }}
                    className={clsx(
                      isChatWinOpen ? "visible" : "invisible",
                      " fp-5"
                    )}
                  >
                    <CloseIcon className="text-white" />
                  </IconButton>
                ) : (
                  <MoreVertIcon />
                )}
              </div>
            )}
          </Toolbar>
        </AppBar>
      ) : (
        <div>
          {last_active && (
            <Typography
              color="textSecondary"
              variant="subtitle1"
              className="py-2 text-center"
              style={{
                top: 0,
                width: "calc( 100% - 40px )",
                marginTop: "1.5px"
              }}
            >
              Active{" "}
              {moment(last_active).fromNow() === "a few seconds ago" ||
              moment(last_active).fromNow() === "in a few seconds"
                ? ""
                : moment(last_active).fromNow()}
            </Typography>
          )}
        </div>
      )}
      <Collapse
        in={isChatWinOpen}
        className={`position-relative w-100 d-flex`}
        classes={{
          container: `flex-grow-1 ${props.boxMode && classes.collapseHeight}`,
          wrapper: `${classes.wrapper} ${props.boxMode &&
            isChatWinOpen &&
            "w-100"}`,
          wrapperInner: classes.wrapperInner
        }}
      >
        <div id="chatArea" className={classes.chatArea} onScroll={onScroll}>
          {show_date && <ShowDate date={date1} />}
          {displayMsgs &&
            displayMsgs.map((msg, index) => (
             <Message msg={msg} propsId={props.id} ext={ext} 
              handleOpenMessageOption={handleOpenMessageOption}
              messages={displayMsgs}
              index={index}
              scrollToMessage={scrollToMessage}
              key={msg.id}
              user={user}
              viewWidth={viewWidth}
              openSchedule={() => console.log('setting schedule', msg.data()) || setAIPrompt(msg.data())}
             />
            ))}
          {(lastFetchedMsg || latestFetchedMsg) && fetching && (
            <div className={classes.chatLoader}
              style={{
                ...(fetchDir === "prev" ? {top: 10} : {bottom: 80})
              }}
            >
              <CircularProgress size={22} />
            </div>
          )}
          {latestMsg && !latestMsg.metadata.hasPendingWrites && seen && (
            <Typography
              variant="subtitle1"
              id="seen"
              className="text-center mx-auto fp-10 "
              style={{ color: "#70829b" }}
            >
              — Seen —
            </Typography>
          )}
          <div id="messagesEnd" />
        </div>
        {!_.isEmpty(user) && !user.onboarding_start_date && (
            <Typography
              variant="subtitle1"
              id="note"
              className="text-center mx-auto fp-10 "
              style={{ color: "#70829b" }}
            >
              Your messages will be delivered after the customer logs in
            </Typography>
        )}
        {!props.enable_chat && (
          <div className="d-flex align-items-center justify-content-center w-100" style={{ backgroundColor: "#70829B", height: "36px"}}>
            <Typography
              variant="caption"
              className="text-white"
            >
              {DISABLED_CHAT_TEXT}
            </Typography>
          </div>
        )}
        <Paper className={classes.rootPaper}>
            <TargetMessagePreview 
              msgData={replyData}
              className="flex-1"
              onClose={cancelReply}
              propsId={props.id}
              userName={targetMessageUserName}
            />
          <div className="d-flex align-items-center">
            <Flag flagged={flagged} flagging={flagging} toggleFlag={toggleFlag}/>
            <IconButton
              className="primary"
              onKeyPress={(ev) => {
                ev.preventDefault();
              }}
              onClick={() => {
                inputEl.current.click();
              }}
            >
              <AttachFileIcon/>
            </IconButton>
            <IconButton
              onClick={() => {
                setShowAudio(true)
              }}
              onKeyPress={(ev) => {
                ev.preventDefault();
              }}
            >
              <Mic />
            </IconButton>
            <div
              style={{ width: "90%" }}
              className="d-flex position-relative"
            >
              {showEmoji ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <span className={classes.emojis}>
                    <Picker onSelect={addEmoji} showPreview={false} />
                  </span>
                </ClickAwayListener>
              ) : (
                ""
              )}
              <InputBase
                className={`ml-10 w-100 ${classes.input} `}
                placeholder={sendOnEnter ? 'Type a message. Hit Enter/Return to send' : 'Type a message...'}
                autoFocus={true}
                value={newMsg.body || ""}
                inputRef={textInput}
                id="inputArea"
                startAdornment={
                  <InputAdornment position="start">
                    <div
                      onClick={handleshowEmojis}
                      className="position-absolute cursor-pointer"
                    >
                      <img src={smile} alt="emojiIcon" />
                    </div>
                  </InputAdornment>
                }
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={function(e) {
                  var val = e.target.value;
                  e.target.value = '';
                  e.target.value = val;
                }}
                onKeyPress={handleKeyPress}
                classes={{
                  inputMultiline: "pl-4 py-1 hide_scroll_bar"
                }}
                multiline={true}
              />
            </div>
            <IconButton
              color="primary"
              className={"fml-20 p-12"}
              classes={{root: "bg-primary"}}
              aria-label="send"
              onClick={(e) => handleKeyPress(e, true)}
              disabled={!newMsg.body}
            >
              <img src={send_icon} alt="sendIcon" />
            </IconButton>
          </div>
          <ScrollToBottom
            unread={unread}
            show={show && !previewFile.file && !!isChatWinOpen && !showAudio}
            style={{
              position: "absolute",
              // 44px button height + 20px gap
              top: "-64px"
            }}
            onClick={handleBottomScroll}
          />
        </Paper>

        {previewFile && previewFile.file && !previewFile.isAudio  &&  (
          <MediaPreview previewFile={previewFile} onSend={handleSend} onCancel={cancelPreview} />
        )}
      </Collapse>
      {showAudio && (
        <AudioRecorder
          onSave={handleSend}
          onChange={(data) => handleCallback(data)}
          open={showAudio}
          onClose={cancelPreview}
          hideAction={!previewFile || !previewFile.file}
        />
      )}
      <input
        id="file-picker"
        ref={inputEl}
        type="file"
        className="d-none"
        accept="image/png, image/jpg, image/jpeg, video/mp4, video/mov, video/webm, video/ogg, video/quicktime, .doc, .docx, .xls, .xlsx, .pdf"
        onChange={addedFile}
      />
      {aiChat && <AIScheduleGenerator 
        prompt={aiPrompt}
        messageId={aiPrompt.id}
        userDoc={props.userDoc}
        companyId={cid}
        uid={props.id}
        planDoc={props.planDoc}
        questions={props.questions}
        sendMessage={sendMessage}
        hidden
      />}
      <Menu
        anchorEl={anchorEl}
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        classes={{
          paper: `fb-border ${classes.zIndex}`,
          list: "p-0"
        }}
        id="delete_pop_up"
        onClick={handleCloseMenu}
      >
        <MenuItem key="reaction" onClick={(e)=>handleReact(e, focusedMsg.current)}>
          {isMessageReacted(focusedMsg.current, uid) ? 'Remove Reaction' : 'Add Reaction'}
        </MenuItem>
        <MenuItem key="reply" onClick={handleReply}>
            Reply
        </MenuItem>
        {downloadResource.type !== 'text' && (
          <MenuItem key="download" onClick={beginDownload}>
            Download
          </MenuItem>
        )}
        <MenuItem key="delete" onClick={toggleDeleteConfirm}>
          Delete
        </MenuItem>
      </Menu>
        <Popover 
          open={Boolean(reactAnchorEl)}
          anchorEl={reactAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ClickAwayListener onClickAway={() => setReactAnchorEl(null)}>
            <span className={classes.reactionPicker}>
              <Picker onSelect={reactMessage} showPreview={false} />
            </span>
          </ClickAwayListener>
        </Popover>
      <Confirmation
        title="Please confirm"
        confirmOption="Yes, Delete"
        msg="Are you sure you want to delete the message?"
        open={!!deleteConfirm}
        handleCancel={toggleDeleteConfirm}
        handleChange={handleDeleteMessage}
      />
    </div>
  );
};

const mapDispatchToProps = d => {
  return {
    ...appRdxFns(d)
  };
};

export default withWidth()(
  withSnackbar(connect(null, mapDispatchToProps)(ChatView))
);

const Flag = ({ flagged, flagging, toggleFlag }) => {
  return (
    <Tooltip title={flagging ? 'Processing...' : (flagged ? 'Starred conversation. Click to remove' : 'Star conversation for quick access later')}>
      <IconButton color={flagged ? 'primary' : 'default'} onClick={toggleFlag} disabled={flagging}>
        {flagging ? <CircularProgress size={24}/> : <i className={`${flagged ? 'fas' : 'far'} fa-star fa-sm`}></i>}
      </IconButton>
    </Tooltip>
  );
};

export const getImageDownloadUrl = async ({ identifier: urlIn, storageFolderRef }) => {
  if (!urlIn) return;
  let url = `${storageFolderRef}/original/${urlIn}`;
  const downloadUrl = await storageRef.child(`${url}`).getDownloadURL();
  return downloadUrl;
};

export const getDownloadUrl = async (id, attachmentData) => {
  const { attachment: { identifier, completed } = {} } = attachmentData;
  const base_path = _.get(attachmentData, 'attachment.base_path', false);
  if (!completed) return;
  const url = base_path
    ? `${base_path}/chats/attachment/${identifier}`
    : `user_profiles/${id}/chats/attachment/${identifier}`;
  const downloadUrl = await storageRef.child(url).getDownloadURL();
  return downloadUrl;
};
