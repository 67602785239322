import React, { useContext, useMemo, useEffect, useState } from 'react';
import { Typography, Button, Switch } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddBtn from './addBtn';
import CopyBtn from './copyBtn';
import MasterPill from './master-pill';
import { AddWoCard, AddMlCard, ItemCard, WaterCard,ActivityCard } from './cards';
import { UserSchContext } from '../provider';

const IDTODAY = 'today-day-row';

const DraggableItemCard = ({ id, index=0, day, type, disabled=false, future = false, rhs = false, ...rest }) => {
  return (
    <Draggable draggableId={day + ':' + (type || 'wo') + ':' + id} index={index} isDragDisabled={id === 'rest' || disabled || rhs || !future}>
      {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <ItemCard id={id} index={index} day={day} type={type} disabled={disabled} future={future} rhs={rhs} {...rest}/>
      </div>)}
    </Draggable>
  );
};

const DayRow = ({week, weekId, day, date, data, opcopy, dayToday, weekToday, currWeek, controller, objCache, ftAltWOs = false, isPerDay = false, dnd = null,key}) => {
  const [id, l2, l2css,l3css, isEditable,today,tomorrow] = useMemo(() => {
    const isToday = (week * 7 + day) === dayToday;
    const isTomorrow = (week * 7 + day) === dayToday+1;
    const isEditable = ((dayToday < 0) && !!weekToday)? false : (week * 7 + day) >= dayToday;
    return ['day' + (day + 1), date.format('ddd, D MMM'), 
    'font_15_600 flex-shrink-1' + (!isEditable ? ' text-65768C' :''),
    'font_15_500 flex-shrink-0' + (!isEditable ? ' text-65768C' :''),
    isEditable,!!isToday,!!isTomorrow];
  }, [week, day, date, dayToday,weekToday]);
  const [copying, target] = useMemo(() => {
    if (!opcopy) return [];
    // if (['ml', 'singleML'].includes(opcopy.purpose) && !isPerDay) return [true];
    const {weekId: srcWeek, day: srcDay} = opcopy;
    if (!srcWeek || !srcDay) return [true];
    const isTarget = isEditable && (weekId !== srcWeek || day !== srcDay - 1);
    return [true, isTarget];
  }, [weekId, day, opcopy, isEditable, isPerDay]);
  const [wolist, isRest] = useMemo(() => {
    if (!data) return [[], false];
    const {wa, wb} = data;
    if (wb) return [wb, wb.length && wb[0] === 'rest'];
    return [wa || [], wa && wa.length && wa[0] === 'rest'];
  }, [data]);
  const [mlId,mlon,mloff] = useMemo(() => {
    const {ma,mb}=data||{};
    const out=mb||ma;
    if(!out) return [null,false,false];
    const {onoff, on, off, single} = out;
    const val= onoff ? (isRest ? off : on) : single;
    return [val==="none"? null:val , onoff && !isRest , onoff && isRest];
  }, [ data]);
  const [slId,slon,sloff] = useMemo(() => {
    const {sa,sb}=data||{};
    const out=sb||sa;
    if(!out) return [null,false,false];
    const {onoff, on, off, single} = out;
    const val= onoff ? (isRest ? off : on) : single;
    return [val==="none"? null:val , onoff && !isRest , onoff && isRest];
  }, [ data]);
  const isDropDisabled = useMemo(() => {
    /* eslint-disable eqeqeq */
    if (Boolean(opcopy)) return true;
    if (!isEditable) return true;
    if (dnd) {
      const [dayStr, type, id] = dnd.split(':');
      if (dayStr == day) return false; // can always drop stuff on myself. XXX triple eq is deliberately not used
      return (type === 'wo' && wolist.includes(id));
    }
  }, [dnd, opcopy, isEditable, day, wolist]);
  const toggle = (e) => {
    const enabled = e.target.checked;
    controller.toggleRest(weekId, week, day, enabled);
  };
  const disabled = Boolean(copying);
  return (
    <div className='day pt-20 bg-white border-bottom' id={id}>
        <div className='daybar d-flex flex-row align-items-center mb-20 pl-20 pr-30'>
          <Switch size="small" color='primary' edge='start' className='my-n12 mr-8' checked={!isRest} disabled={disabled || !isEditable} onChange={toggle}/>
          <Typography noWrap className={l2css}>{l2}</Typography>
          <Typography noWrap className={l3css}>
            &nbsp;&nbsp;•&nbsp;&nbsp;
            W{week+1} D{day + 1}
            {!!today && <span className='ml-20 text-primary'>Today</span>}
            </Typography>
          <div className='flex-grow-1'/>
          {/* <MasterPill week={week} weekId={weekId} day={day} dayToday={dayToday} data={data} l2={l2} controller={controller}/> */}
          <AddBtn week={week} weekId={weekId} day={day} hidden={copying || !isEditable} axn={controller.importClick} isPerDay={isPerDay}/>
          <CopyBtn weekId={weekId} day={day} hidden={copying} axn={controller.copyClick} hasWOs={!isRest && wolist && wolist.length} hasMLs={!!mlId}/>
          {target && <Button color='primary' variant='outlined' data-week={week} data-weekid={weekId} data-day={day} onClick={controller.copyPaste}>Paste</Button>}
      </div>
      <Droppable direction='horizontal' droppableId={weekId + ':' + week + ':' + day} isDropDisabled={isDropDisabled}>
        {(provided) => (<div {...provided.droppableProps} ref={provided.innerRef} className='overflow-x-auto overflow-y-hidden pl-20 pr-30'>
          <div className='d-flex flex-row w-mincont mb-20'>
            {!wolist.length && <AddWoCard week={week} weekId={weekId} day={day} axn={controller.importClick} disabled={disabled} future={isEditable} controller={controller} isToday={today} isTomorrow={tomorrow}/>}
            {wolist.map((id, n) => <DraggableItemCard type={id==="rest"?id:"wo"} key={id} index={n} id={id} week={week} weekId={weekId} day={day}
              disabled={disabled} future={isEditable} controller={controller}
              objCache={objCache} dayCopy={data && data.copy} ftAltWOs={ftAltWOs}/>)}
            {!mlId && <AddMlCard week={week} weekId={weekId} day={day} index={wolist.length} axn={controller.importClick} disabled={disabled} future={isEditable} controller={controller} isToday={today}  isTomorrow={tomorrow}/>}
            {mlId && <DraggableItemCard on={mlon} off={mloff} type='ml' index={wolist.length} id={mlId} week={week} weekId={weekId} day={day}
              disabled={disabled} future={isEditable} controller={controller}
              objCache={objCache} dayCopy={data && data.copy} />}
            {slId && <DraggableItemCard on={slon} off={sloff} type='sl' index={wolist.length} id={slId} week={week} weekId={weekId} day={day}
              future={isEditable} controller={controller}
              objCache={objCache} dayCopy={data && data.copy} />}
            {provided.placeholder}
            <WaterCard week={week} weekId={weekId} day={day} axn={isEditable && controller.importClick} disabled={disabled || !isEditable} future={isEditable}/>
            <ActivityCard week={week} weekId={weekId} day={day} axn={isEditable && controller.importClick} disabled={disabled || !isEditable} future={isEditable}/>
          </div>
        </div>)}
      </Droppable>
    </div>
  );
};

const WeekDays = (props) => {
  const { dates, opcopy, objCache, dayToday, weekToday, currWeek, currWeekId, local, ftAltWOs, controller } = useContext(UserSchContext);
  const [dragging, setDragging] = useState(null);
  const weekData = local.weeks[currWeekId] || {};
  const { data = {}, ml } = weekData;
  const isPerDay = ml && ml.mode === 'per_day';
  useEffect(() => { // scroll to top on week change
    window.document.getElementById('main').scrollTo(0, 0);
  }, [currWeek]);

  const onDragStart = (event) => setDragging(event.draggableId);
  const onDragEnd = (event) => {
    setDragging(null);
    const { draggableId, source, destination } = event;
    if (!draggableId || !source || !destination) return;
    const [srcDay, type, id] = draggableId.split(':');
    const { droppableId, index: dstIndex } = destination;
    const { index: srcIndex } = source;
    const [weekId, week, dstDay] = droppableId.split(':');
    controller.cutPaste(weekId, week, srcDay, srcIndex, dstDay, dstIndex, type, id);
  };

  if (!dates || !dates.length) return null;
  return (
    <div className='h-100 overflow-auto'>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {dates.map((date, n) => <DayRow dnd={dragging} isPerDay={isPerDay} key={date.format('YYYYMMDD')}
        week={currWeek} weekId={currWeekId} day={n} dayToday={dayToday} weekToday={weekToday}
        date={date} opcopy={opcopy} data={data['d' + (n+1)]}
        controller={controller} objCache={objCache} ftAltWOs={ftAltWOs} />)}
      </DragDropContext>
    </div>
  );
};

export default WeekDays;
